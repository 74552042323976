.mad-reviews-page {
  margin-top: 75px;
  padding: 0;
  font-size: 16px;
  overflow-x: hidden;

  @media screen and (max-width: 575px) {
    margin: 0;
    font-size: 14px;
  }

  .mad-reviews-landing {
    padding: 50px 190px;

    @media screen and (max-width: 1200px) {
      padding: 50px 90px;
    }

    @media screen and (max-width: 575px) {
      padding: 45px;
    }

    .mad-reviews-backdrop {
      position: absolute;
      height: 180px;
      width: 180px;
      top: 0;
      right: 20px;
      opacity: 0.06;
    }

    .mad-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;

      @media screen and (max-width: 575px) {
        padding: 20px;
      }

      img {
        margin: 5px;
        border-radius: 5px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        max-width: 75%;

        transition: 0.2s;

        &:hover {
          transform: scale(1.03);
        }
      }

      @media screen and (max-width: 991px) {
        img {
          max-width: 60%;
        }
      }

      @media screen and (max-width: 767px) {
        img {
          max-width: 75%;
          margin-bottom: 15px;
        }
      }
    }

    .mad-reviews-bio {
      padding: 0px 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1300px) {
        padding: 0 15px;
      }
    }
  }

  .mad-dark {
    h4 {
      color: #8560a7;
    }

    p {
      color: #8560a7;
      font-weight: 500;
    }
  }

  .mad-reviews-details {
    padding: 50px 190px;
    background-color: #e9e0f9;
    color: #8560a7;

    @media screen and (max-width: 1200px) {
      padding: 50px 90px;
    }

    @media screen and (max-width: 575px) {
      padding: 45px;
    }

    .mad-reviews-profiles {
      padding: 40px;

      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          border-radius: 50%;
          width: 350px;
          height: 350px;
          transition: 0.3s;

          &:hover {
            transform: scale(1.05);
          }

          @media screen and (max-width: 991px) {
            max-width: 90%;
          }
        }

        .mad-pup-title {
          font-weight: 900;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
