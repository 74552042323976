.container {
  // max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  max-height: 100vh;
  max-width: 100%;

  .mad-mobile-nav {
    background: white;
    padding: 20px;
    position: fixed;
    top: 0;
    left: -400px;
    z-index: 100;
    box-shadow: 3px 2px 12px 2px rgba(0, 0, 0, 0.2);
    width: 35%;
    height: 100vh;

    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;

    @media screen and (min-width: 991px) {
      display: none;
    }

    @media screen and (max-width: 767px) {
      width: 40%;
    }

    @media screen and (max-width: 575px) {
      width: 50%;
    }

    @media screen and (max-width: 414px) {
      width: 60%;
    }

    a {
      width: 100%;
    }

    .mad-nav-header {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ccc;

      .mad-nav-header-logo {
        max-width: 50px;
        max-height: 45px;
        margin-bottom: 10px;
      }
    }

    .mad-mobile-nav-item {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #ccc;

      a {
        margin: 0;
      }
    }
  }

  .mad-mobile-nav.show {
    left: 0;
  }
}
.footer {
  background: #ececec;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 5.555555556em;
  @media screen and(max-width: 767px) {
    padding: 0 20px;
  }
  flex-flow: row wrap;
  .column {
    @media screen and(max-width: 767px) {
      width: 100%;
      margin-top: 40px;
    }
    &.first {
      max-width: 265px;
    }
    span {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: 0.5px;
      color: #4a4a4a;
    }
  }
  h4 {
    &.heading {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: #000000;
      margin-bottom: 20px;
    }
  }
  ul.link-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 20px;
      a {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        color: #4a4a4a;
      }
    }
  }
}
