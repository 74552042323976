.mad-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
  padding: 0px 220px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1400px) {
    padding: 0 150px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 45px;
  }

  @media screen and (max-width: 575px) {
    position: relative;
    flex-direction: column;
    padding: 0px;
    padding-bottom: 10px;
  }

  .mad-nav-logo {
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    margin: 10px 0;
    h4 {
      font-family: "Pacifico", sans-serif;
      font-weight: 900;
      font-size: 16px;
      color: #8560a7;
      margin: 0;
      margin-left: 0.5rem;

      @media screen and (max-width: 575px) {
        margin: 0;
        margin-left: 10px;
        margin-top: 15px;
      }
    }
  }

  .mad-nav-links {
    a {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.2px;
      margin: 20px;
      color: #8560a7;
      text-transform: uppercase;

      @media screen and (max-width: 575px) {
        margin: 20px 10px;
      }
    }
  }
}
