.App {
    height: 100vh;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
}

body {
    font-size: 16px;

    @media screen and(max-width: 991px){
        font-size: 16px;
    }

    @media screen and(max-width: 575px){
        font-size: 14px !important;
    }
}
