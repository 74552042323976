.mad-about-page {
  margin-top: 55px;
  padding: 0;

  @media screen and (max-width: 575px) {
    margin: 0;
  }

  .mad-about-landing {
    margin: 0;
    padding: 50px 190px;

    @media screen and (max-width: 1200px) {
      padding: 50px 90px;
    }

    @media screen and (max-width: 575px) {
      padding: 45px 15px;
    }

    img {
      border-radius: 5px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      width: 75%;
    }

    .mad-image-container {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 991px) {
        img {
          max-width: 60%;
        }
      }

      @media screen and (max-width: 767px) {
        img {
          max-width: 100%;
          margin-bottom: 15px;
        }
      }
    }

    .mad-about-bio {
      padding: 0px 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1300px) {
        padding: 0 15px;
      }

      h4 {
        padding: 0 20px;
      }

      p {
        padding: 0px 20px;
      }
    }
  }

  .mad-about-details {
    margin: 75px 0 0;
    padding: 50px 190px;

    h2 {
      color: #8560a7;
    }

    h4 {
      padding: 0px 20px;
    }

    @media screen and (max-width: 1200px) {
      padding: 50px 90px;
    }

    @media screen and (max-width: 575px) {
      padding: 45px 15px;
    }

    .mad-about-profiles {
      padding: 40px 15px;

      .profile {
        .wrapper {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          border-radius: 5px;
          width: 100%;

          @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
          }
        }

        .text-wrapper {
          padding: 0 20px;
        }

        img {
          border-radius: 5px;
          width: 200px;
          height: 200px;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);

          @media screen and (max-width: 991px) {
            max-width: 90%;
          }

          @media screen and (max-width: 575px) {
            width: 200px;
            height: 200px;
          }
        }

        .mad-pup-title {
          font-weight: 900;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0;
          font-family: "Lato", sans-serif;
        }

        p {
          width: 400px;

          @media screen and (max-width: 575px) {
            max-width: 250px;
          }
        }
      }
    }
  }
}
