/* Variables */
$theme-colors: (
  "theme": #ffffff,
  "primary": #8560a7,
  "danger": #c42527,
  "dark": #e9e0f9,
  "black": #000000,
  "warning": #fea500,
  "secondary": #eef1f6,
  "info": #2174a5,
  "mdblue": #0a3782,
  "mdorange": #ff7700,
  "mdgreen": #129145,
  "success": #129145,
  "uigray500": #63666a,
  "uiblue700": #010e23,
  "uiblue600": #041b43,
  "uiblue500": #0a3782,
  "uiblue400": #325796,
  "uiblue300": #637faf,
  "uiblue200": #97aaca,
  "uiblue100": #c5d0e1,
  "uiblue20": #eef1f6,
  "accentyellow": #ffbf00,
  "accentpink": #e83f6f,
  "accentblue": #2174a5,
  "accentlightblue": #0077e2,
  "accentgreen": #129145,
  "accentgrey": #f4f8f9,
  /*Domoreport variables*/ "urgent": #b2140a,
  "pressing": #fea500,
  "cosmetic": #003782,
);

@import "node_modules/bootstrap/scss/bootstrap";
@each $color, $value in $theme-colors {
  .btn-#{$color}:hover {
    background: $value;
    border: $value;
  }
}

@each $color, $value in $theme-colors {
  .mad-#{$color} {
    background: $value;
  }
}

.bg-black {
  color: #000000;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

.rotate-90 {
  @include rotate(90deg);
}

.rotate-180 {
  @include rotate(180deg);
}

.rotate-270 {
  @include rotate(270deg);
}

.btn-large {
  border-radius: 0;
  min-height: 225px;
  min-width: 250px;

  @media screen and(min-width: 769px) and (max-width: 1200px) {
    min-height: 90%;
    min-width: 90%;
    padding: 1.5em;
    font-size: 1.5em;
  }

  @media screen and(max-width: 768px) {
    min-height: 100%;
    min-width: 100%;
    padding: 1.5em;
    font-size: 1.5em;
  }

  transition: all 0.3s ease-in-out;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.6666667em;
  text-align: left;
  font-family: "Lato", sans-serif;
  padding: 1.6666666667em;
  transform-origin: 50% 50% 50%;
  backface-visibility: hidden;

  div {
    margin-top: auto;
  }

  &:hover {
    transform: perspective(1px) scale(1.03) translate3d(0, 0, 0);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  }
}

.mad-spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .mad-loading-spinner {
    border: 3px solid #0077e2;
    border-top-color: #0a3782;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
