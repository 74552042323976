.mad-home-page {
  padding: 0;

  h4 {
    color: #000000;
  }

  p {
    color: #000000;
  }

  .mad-landing {
    margin: 0;
    margin-top: 20px;
    background: url("/img/hero.jpg");
    background-attachment: fixed;
    background-position: center 5px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    width: 100%;
    min-height: 1050px;
    padding: 40px 20px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 0;
      background-attachment: fixed;
      background-size: cover;
      min-height: 1050px;
    }

    @media screen and (max-width: 575px) {
      margin-top: 0px;
      margin-bottom: 0;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      min-height: 500px;
    }

    .mad-landing-text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 250px;

      @media screen and (max-width: 575px) {
        padding-bottom: 50px;
      }

      .mad-landing-text {
        max-width: 400px;

        @media screen and (min-width: 1200px) {
          max-width: 600px;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          max-width: 100vw;
        }

        h2 {
          transform: skewY(-10deg);
          font-family: "Pacifico", sans-serif;
          font-size: 100px;
          font-weight: 900;
          color: #ffffff;
          text-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
          margin-bottom: 60px;

          @media screen and (max-width: 1200px) {
            font-size: 70px;
            margin-bottom: 40px;
          }

          @media screen and (max-width: 575px) {
            font-size: 60px;
          }

          @media screen and (max-width: 414px) {
            font-size: 45px;
          }
        }

        p {
          text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.6);
          margin-bottom: 20px;
          font-style: italic;
          font-size: 24px;
          color: #ffffff;

          @media screen and (max-width: 575px) {
            font-size: 16px;
            max-width: 380px;
            margin: auto;
          }

          b {
            color: #e9e0f9;
            font-weight: 900;
          }
        }

        .mad-landing-cta-wrapper {
          display: flex;
          align-items: flex-start;

          .mad-landing-cta {
            text-align: center;
            border: 1px solid #e9e0f9;
            border-radius: 3px;
            padding: 10px 20px;
            background: #e9e0f9;
            color: #8560a7;
            font-weight: 900;
            text-transform: capitalize;
            margin-right: 10px;
            transition: 0.1s;
            text-decoration: none;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

            &:hover {
              transform: scale(1.05);
              box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
              background: #8560a7;
              border: 1px solid #8560a7;
              color: #e9e0f9;
            }

            @media screen and (max-width: 767px) {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .mad-landing-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px;

      .mad-accent {
        position: absolute;
        bottom: 5%;
        left: 12%;
        width: 150px;
        height: 150px;
        border: 3px solid #e8b48c;
        z-index: -10;
      }
    }
  }

  .mad-icons {
    padding: 100px 30px;
    align-items: center;
    justify-content: center;

    h4 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 50px;
    }

    .mad-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100px;
    }
  }

  .mad-about {
    margin: 0;
    padding: 10px 130px;
    position: relative;
    z-index: 3;
    border-bottom: 1px solid #eeeeee;

    @media screen and (max-width: 991px) {
      margin-top: -300px;
      margin-bottom: -125px;
      padding: 70px 60px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;

      .row {
        margin: 0;
        justify-content: center;
      }
    }

    @media screen and (max-width: 575px) {
      margin: 0;
      padding: 20px 25px;
    }

    .mad-about-upper {
      display: flex;
      margin: 50px 0;

      @media screen and (max-width: 991px) {
        margin-top: 10px;
        margin-bottom: 30px;
        align-items: center;
        padding: 0;
      }

      .mad-about-text {
        text-align: left;

        @media screen and (max-width: 991px) {
          text-align: left;
        }

        @media screen and (max-width: 767px) {
          padding: 0;
        }

        @media screen and (max-width: 575px) {
          padding-top: 0;
        }
      }
    }

    .mad-about-image {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 400px;
        z-index: 1;
        border-radius: 5px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        @media screen and (max-width: 991px) {
          width: 300px;
        }

        @media screen and (max-width: 413px) {
          width: 230px;
        }
      }

      @media screen and (min-width: 992px) {
        .mad-accent-left {
          position: absolute;
          bottom: 80px;
          left: calc(17% - 50px);
          width: 300px;
          height: 300px;
          z-index: 0;
          border: 1px solid #eeeeee;
          border-radius: 3px;

          @media screen and (max-width: 1400px) {
            bottom: 120px;
            left: calc(10% - 50px);
          }

          @media screen and (max-width: 1200px) {
            bottom: 120px;
            left: calc(5% - 50px);
          }

          @media screen and (max-width: 1024px) {
            bottom: 180px;
            left: calc(5% - 50px);
          }
        }

        // .mad-accent-right {
        //     position: absolute;
        //     bottom: 50px;
        //     right: 17%;
        //     width: 400px;
        //     height: 400px;
        //     background: white;
        //     z-index: 0;
        // }
      }
    }

    .mad-about-text {
      padding: 60px;

      @media screen and (max-width: 1200px) {
        padding: 30px;
      }

      @media screen and (max-width: 991px) {
        padding: 30px 15px;
      }

      @media screen and (max-width: 767px) {
        max-width: 400px;
      }

      @media screen and (max-width: 575px) {
        padding: 0px;
        padding-left: 15px;
        padding-right: 15px;
      }

      h4 {
        font-weight: 900;
        font-size: 30px;
        margin-bottom: 15px;

        @media screen and (max-width: 575px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .mad-available-container {
    position: relative;
    z-index: 0;
    margin: 0;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-left: 0;
    }

    @media screen and (max-width: 575px) {
      margin-right: 0;
      margin-left: 0;
    }

    .mad-available-images {
      background: url("/img/IMG_0501.jpeg") no-repeat;
      background-attachment: fixed;
      background-position: left -50px;

      @media screen and (max-width: 1400px) {
        background-position: -50px;
      }

      @media screen and (max-width: 1200px) {
        background-position: -90px -50px;
      }

      @media screen and (max-width: 1024px) {
        background: url("/img/IMG_0501-M.jpg") no-repeat;
        background-position: -65px;
        background-attachment: scroll;
        background-size: cover;
      }

      @media screen and (max-width: 991px) {
        background-position: -30px bottom;
        background-attachment: scroll;
      }

      @media screen and (max-width: 767px) {
        background: url("/img/IMG_0501-S.jpg") no-repeat;
        background-position: center;
        background-size: contain;
        min-height: 500px;
        margin-top: 50px;
      }

      @media screen and (max-width: 575px) {
        min-height: 400px;
      }

      a {
        padding: 10px;
        background: #000000;
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 14px;
        font-weight: 900;
        border-radius: 50px;
        color: #000000;
      }

      .mad-available {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;

        .image {
          width: 150px;
          height: 150px;
          margin: 20px;
          background-color: #e8b48c;
        }

        p {
          text-transform: uppercase;
        }
      }
    }

    &.lower {
      .mad-available-info {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 260px 75px;

        @media screen and (max-width: 991px) {
          padding: 160px 45px;
        }

        @media screen and (max-width: 767px) {
          padding: 80px 45px;
        }

        @media screen and (max-width: 575px) {
          padding: 50px 25px;
        }

        h4 {
          color: #000000;
        }

        .btn {
          text-align: center;
          border: 1px solid #e9e0f9;
          border-radius: 3px;
          padding: 10px 20px;
          background: #e9e0f9;
          color: #8560a7;
          font-weight: 900;
          text-transform: capitalize;
          margin-right: 10px;
          transition: 0.1s;
          text-decoration: none;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
          margin-top: 10px;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            background: #8560a7;
            border: 1px solid #8560a7;
            color: #e9e0f9;
          }
        }
      }

      .mad-available-image-right {
        background: url("/img/litter2.jpg") no-repeat;
        background-size: contain;
        background-attachment: fixed;
        background-position: right 95%;

        @media screen and (max-width: 1400px) {
          background-position: 140% 85%;
        }

        @media screen and (max-width: 1200px) {
          background-position: 190% 80%;
        }

        @media screen and (max-width: 1100px) {
          background-position: 230% 80%;
        }

        @media screen and (max-width: 1024px) {
          background-position: center;
          background-attachment: scroll;
          background-size: cover;
        }

        .mad-available {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 40px;

          .image {
            width: 150px;
            height: 150px;
            margin: 20px;
            background-color: #e8b48c;
          }

          p {
            text-transform: uppercase;
          }
        }
      }
    }

    .mad-available-info {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 200px 55px 210px 55px;

      @media screen and (max-width: 991px) {
        padding: 200px 45px 140px 45px;
      }

      @media screen and (max-width: 767px) {
        padding: 60px 45px;
      }

      @media screen and (max-width: 575px) {
        padding: 50px 25px;
      }

      h4 {
        width: 100%;
        max-width: 450px;
        margin-top: 40px;
        font-weight: 900;
        color: #8560a7;

        @media screen and (max-width: 767px) {
          max-width: 400px;
          width: 100%;
          margin-top: 0;
        }
      }

      p {
        max-width: 450px;
        color: #333;

        @media screen and (max-width: 767px) {
          max-width: 400px;
          margin: 0;
        }
      }

      a {
        font-weight: 900;
      }
    }
  }

  .mad-learn-more {
    position: fixed;
    bottom: 30px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    p {
      text-transform: uppercase;
      font-size: 14px;
      margin: 0;
      color: #ffffff;
    }

    i {
      color: #ffffff;
      font-size: 30px;
    }
  }
}
