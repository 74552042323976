.mad-not-found-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    color: #ffffff;

    p {
        font-size: 14px;
        font-style: italic;
    }
}