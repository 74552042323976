/* Variables */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #8560a7;
  --secondary: #eef1f6;
  --success: #129145;
  --info: #2174a5;
  --warning: #fea500;
  --danger: #c42527;
  --light: #f8f9fa;
  --dark: #e9e0f9;
  --theme: #ffffff;
  --black: #000000;
  --mdblue: #0a3782;
  --mdorange: #ff7700;
  --mdgreen: #129145;
  --uigray500: #63666a;
  --uiblue700: #010e23;
  --uiblue600: #041b43;
  --uiblue500: #0a3782;
  --uiblue400: #325796;
  --uiblue300: #637faf;
  --uiblue200: #97aaca;
  --uiblue100: #c5d0e1;
  --uiblue20: #eef1f6;
  --accentyellow: #ffbf00;
  --accentpink: #e83f6f;
  --accentblue: #2174a5;
  --accentlightblue: #0077e2;
  --accentgreen: #129145;
  --accentgrey: #f4f8f9;
  --urgent: #b2140a;
  --pressing: #fea500;
  --cosmetic: #003782;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #8560a7;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #5e4278;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ddd2e6; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c0acd1; }

.table-hover .table-primary:hover {
  background-color: #d1c2dd; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #d1c2dd; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fafbfc; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f6f8fa; }

.table-hover .table-secondary:hover {
  background-color: #eaeef2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #eaeef2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bde0cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #84c69e; }

.table-hover .table-success:hover {
  background-color: #acd8bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #acd8bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c1d8e6; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8cb7d0; }

.table-hover .table-info:hover {
  background-color: #afcddf; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #afcddf; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe6b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fed07a; }

.table-hover .table-warning:hover {
  background-color: #ffdd9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffdd9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #eec2c3; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e08e8f; }

.table-hover .table-danger:hover {
  background-color: #e8aeaf; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e8aeaf; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #f9f6fd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #f4effc; }

.table-hover .table-dark:hover {
  background-color: #ebe1f8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #ebe1f8; }

.table-theme,
.table-theme > th,
.table-theme > td {
  background-color: white; }

.table-theme th,
.table-theme td,
.table-theme thead th,
.table-theme tbody + tbody {
  border-color: white; }

.table-hover .table-theme:hover {
  background-color: #f2f2f2; }
  .table-hover .table-theme:hover > td,
  .table-hover .table-theme:hover > th {
    background-color: #f2f2f2; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-mdblue,
.table-mdblue > th,
.table-mdblue > td {
  background-color: #bac7dc; }

.table-mdblue th,
.table-mdblue td,
.table-mdblue thead th,
.table-mdblue tbody + tbody {
  border-color: #8097be; }

.table-hover .table-mdblue:hover {
  background-color: #a9b9d3; }
  .table-hover .table-mdblue:hover > td,
  .table-hover .table-mdblue:hover > th {
    background-color: #a9b9d3; }

.table-mdorange,
.table-mdorange > th,
.table-mdorange > td {
  background-color: #ffd9b8; }

.table-mdorange th,
.table-mdorange td,
.table-mdorange thead th,
.table-mdorange tbody + tbody {
  border-color: #ffb87a; }

.table-hover .table-mdorange:hover {
  background-color: #ffcb9f; }
  .table-hover .table-mdorange:hover > td,
  .table-hover .table-mdorange:hover > th {
    background-color: #ffcb9f; }

.table-mdgreen,
.table-mdgreen > th,
.table-mdgreen > td {
  background-color: #bde0cb; }

.table-mdgreen th,
.table-mdgreen td,
.table-mdgreen thead th,
.table-mdgreen tbody + tbody {
  border-color: #84c69e; }

.table-hover .table-mdgreen:hover {
  background-color: #acd8bd; }
  .table-hover .table-mdgreen:hover > td,
  .table-hover .table-mdgreen:hover > th {
    background-color: #acd8bd; }

.table-uigray500,
.table-uigray500 > th,
.table-uigray500 > td {
  background-color: #d3d4d5; }

.table-uigray500 th,
.table-uigray500 td,
.table-uigray500 thead th,
.table-uigray500 tbody + tbody {
  border-color: #aeafb2; }

.table-hover .table-uigray500:hover {
  background-color: #c6c7c9; }
  .table-hover .table-uigray500:hover > td,
  .table-hover .table-uigray500:hover > th {
    background-color: #c6c7c9; }

.table-uiblue700,
.table-uiblue700 > th,
.table-uiblue700 > td {
  background-color: #b8bcc1; }

.table-uiblue700 th,
.table-uiblue700 td,
.table-uiblue700 thead th,
.table-uiblue700 tbody + tbody {
  border-color: #7b828d; }

.table-hover .table-uiblue700:hover {
  background-color: #aaafb5; }
  .table-hover .table-uiblue700:hover > td,
  .table-hover .table-uiblue700:hover > th {
    background-color: #aaafb5; }

.table-uiblue600,
.table-uiblue600 > th,
.table-uiblue600 > td {
  background-color: #b9bfca; }

.table-uiblue600 th,
.table-uiblue600 td,
.table-uiblue600 thead th,
.table-uiblue600 tbody + tbody {
  border-color: #7c889d; }

.table-hover .table-uiblue600:hover {
  background-color: #aab2bf; }
  .table-hover .table-uiblue600:hover > td,
  .table-hover .table-uiblue600:hover > th {
    background-color: #aab2bf; }

.table-uiblue500,
.table-uiblue500 > th,
.table-uiblue500 > td {
  background-color: #bac7dc; }

.table-uiblue500 th,
.table-uiblue500 td,
.table-uiblue500 thead th,
.table-uiblue500 tbody + tbody {
  border-color: #8097be; }

.table-hover .table-uiblue500:hover {
  background-color: #a9b9d3; }
  .table-hover .table-uiblue500:hover > td,
  .table-hover .table-uiblue500:hover > th {
    background-color: #a9b9d3; }

.table-uiblue400,
.table-uiblue400 > th,
.table-uiblue400 > td {
  background-color: #c6d0e2; }

.table-uiblue400 th,
.table-uiblue400 td,
.table-uiblue400 thead th,
.table-uiblue400 tbody + tbody {
  border-color: #94a8c8; }

.table-hover .table-uiblue400:hover {
  background-color: #b5c2d9; }
  .table-hover .table-uiblue400:hover > td,
  .table-hover .table-uiblue400:hover > th {
    background-color: #b5c2d9; }

.table-uiblue300,
.table-uiblue300 > th,
.table-uiblue300 > td {
  background-color: #d3dbe9; }

.table-uiblue300 th,
.table-uiblue300 td,
.table-uiblue300 thead th,
.table-uiblue300 tbody + tbody {
  border-color: #aebcd5; }

.table-hover .table-uiblue300:hover {
  background-color: #c2cde1; }
  .table-hover .table-uiblue300:hover > td,
  .table-hover .table-uiblue300:hover > th {
    background-color: #c2cde1; }

.table-uiblue200,
.table-uiblue200 > th,
.table-uiblue200 > td {
  background-color: #e2e7f0; }

.table-uiblue200 th,
.table-uiblue200 td,
.table-uiblue200 thead th,
.table-uiblue200 tbody + tbody {
  border-color: #c9d3e3; }

.table-hover .table-uiblue200:hover {
  background-color: #d1d9e7; }
  .table-hover .table-uiblue200:hover > td,
  .table-hover .table-uiblue200:hover > th {
    background-color: #d1d9e7; }

.table-uiblue100,
.table-uiblue100 > th,
.table-uiblue100 > td {
  background-color: #eff2f7; }

.table-uiblue100 th,
.table-uiblue100 td,
.table-uiblue100 thead th,
.table-uiblue100 tbody + tbody {
  border-color: #e1e7ef; }

.table-hover .table-uiblue100:hover {
  background-color: #dee4ef; }
  .table-hover .table-uiblue100:hover > td,
  .table-hover .table-uiblue100:hover > th {
    background-color: #dee4ef; }

.table-uiblue20,
.table-uiblue20 > th,
.table-uiblue20 > td {
  background-color: #fafbfc; }

.table-uiblue20 th,
.table-uiblue20 td,
.table-uiblue20 thead th,
.table-uiblue20 tbody + tbody {
  border-color: #f6f8fa; }

.table-hover .table-uiblue20:hover {
  background-color: #eaeef2; }
  .table-hover .table-uiblue20:hover > td,
  .table-hover .table-uiblue20:hover > th {
    background-color: #eaeef2; }

.table-accentyellow,
.table-accentyellow > th,
.table-accentyellow > td {
  background-color: #ffedb8; }

.table-accentyellow th,
.table-accentyellow td,
.table-accentyellow thead th,
.table-accentyellow tbody + tbody {
  border-color: #ffde7a; }

.table-hover .table-accentyellow:hover {
  background-color: #ffe79f; }
  .table-hover .table-accentyellow:hover > td,
  .table-hover .table-accentyellow:hover > th {
    background-color: #ffe79f; }

.table-accentpink,
.table-accentpink > th,
.table-accentpink > td {
  background-color: #f9c9d7; }

.table-accentpink th,
.table-accentpink td,
.table-accentpink thead th,
.table-accentpink tbody + tbody {
  border-color: #f39bb4; }

.table-hover .table-accentpink:hover {
  background-color: #f6b2c6; }
  .table-hover .table-accentpink:hover > td,
  .table-hover .table-accentpink:hover > th {
    background-color: #f6b2c6; }

.table-accentblue,
.table-accentblue > th,
.table-accentblue > td {
  background-color: #c1d8e6; }

.table-accentblue th,
.table-accentblue td,
.table-accentblue thead th,
.table-accentblue tbody + tbody {
  border-color: #8cb7d0; }

.table-hover .table-accentblue:hover {
  background-color: #afcddf; }
  .table-hover .table-accentblue:hover > td,
  .table-hover .table-accentblue:hover > th {
    background-color: #afcddf; }

.table-accentlightblue,
.table-accentlightblue > th,
.table-accentlightblue > td {
  background-color: #b8d9f7; }

.table-accentlightblue th,
.table-accentlightblue td,
.table-accentlightblue thead th,
.table-accentlightblue tbody + tbody {
  border-color: #7ab8f0; }

.table-hover .table-accentlightblue:hover {
  background-color: #a1cdf4; }
  .table-hover .table-accentlightblue:hover > td,
  .table-hover .table-accentlightblue:hover > th {
    background-color: #a1cdf4; }

.table-accentgreen,
.table-accentgreen > th,
.table-accentgreen > td {
  background-color: #bde0cb; }

.table-accentgreen th,
.table-accentgreen td,
.table-accentgreen thead th,
.table-accentgreen tbody + tbody {
  border-color: #84c69e; }

.table-hover .table-accentgreen:hover {
  background-color: #acd8bd; }
  .table-hover .table-accentgreen:hover > td,
  .table-hover .table-accentgreen:hover > th {
    background-color: #acd8bd; }

.table-accentgrey,
.table-accentgrey > th,
.table-accentgrey > td {
  background-color: #fcfdfd; }

.table-accentgrey th,
.table-accentgrey td,
.table-accentgrey thead th,
.table-accentgrey tbody + tbody {
  border-color: #f9fbfc; }

.table-hover .table-accentgrey:hover {
  background-color: #edf3f3; }
  .table-hover .table-accentgrey:hover > td,
  .table-hover .table-accentgrey:hover > th {
    background-color: #edf3f3; }

.table-urgent,
.table-urgent > th,
.table-urgent > td {
  background-color: #e9bdba; }

.table-urgent th,
.table-urgent td,
.table-urgent thead th,
.table-urgent tbody + tbody {
  border-color: #d78580; }

.table-hover .table-urgent:hover {
  background-color: #e3aba7; }
  .table-hover .table-urgent:hover > td,
  .table-hover .table-urgent:hover > th {
    background-color: #e3aba7; }

.table-pressing,
.table-pressing > th,
.table-pressing > td {
  background-color: #ffe6b8; }

.table-pressing th,
.table-pressing td,
.table-pressing thead th,
.table-pressing tbody + tbody {
  border-color: #fed07a; }

.table-hover .table-pressing:hover {
  background-color: #ffdd9f; }
  .table-hover .table-pressing:hover > td,
  .table-hover .table-pressing:hover > th {
    background-color: #ffdd9f; }

.table-cosmetic,
.table-cosmetic > th,
.table-cosmetic > td {
  background-color: #b8c7dc; }

.table-cosmetic th,
.table-cosmetic td,
.table-cosmetic thead th,
.table-cosmetic tbody + tbody {
  border-color: #7a97be; }

.table-hover .table-cosmetic:hover {
  background-color: #a7bad4; }
  .table-hover .table-cosmetic:hover > td,
  .table-hover .table-cosmetic:hover > th {
    background-color: #a7bad4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #c4b2d4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #129145; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(18, 145, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #129145;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23129145' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #129145;
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #129145;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23129145' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #129145;
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #129145; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #129145; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #129145; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #18be5b;
  background-color: #18be5b; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #129145; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #129145; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #129145;
  box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #c42527; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(196, 37, 39, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #c42527;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23c42527' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c42527' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #c42527;
    box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #c42527;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23c42527' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c42527' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #c42527;
    box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c42527; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c42527; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #c42527; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #db4143;
  background-color: #db4143; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c42527; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #c42527; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #c42527;
  box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #8560a7;
  border-color: #8560a7; }
  .btn-primary:hover {
    color: #fff;
    background-color: #725091;
    border-color: #6b4c88; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #725091;
    border-color: #6b4c88;
    box-shadow: 0 0 0 0.2rem rgba(151, 120, 180, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #8560a7;
    border-color: #8560a7; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #6b4c88;
    border-color: #654780; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(151, 120, 180, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #eef1f6;
  border-color: #eef1f6; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #d5dce9;
    border-color: #cdd6e4; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #d5dce9;
    border-color: #cdd6e4;
    box-shadow: 0 0 0 0.2rem rgba(207, 210, 215, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #cdd6e4;
    border-color: #c4cfe0; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 210, 215, 0.5); }

.btn-success {
  color: #fff;
  background-color: #129145;
  border-color: #129145; }
  .btn-success:hover {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f;
    box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0c642f;
    border-color: #0b582a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #2174a5;
  border-color: #2174a5; }
  .btn-info:hover {
    color: #fff;
    background-color: #1b5e85;
    border-color: #19567b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #1b5e85;
    border-color: #19567b;
    box-shadow: 0 0 0 0.2rem rgba(66, 137, 179, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #19567b;
    border-color: #164f70; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 137, 179, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fea500;
  border-color: #fea500; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d88c00;
    border-color: #cb8400; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d88c00;
    border-color: #cb8400;
    box-shadow: 0 0 0 0.2rem rgba(221, 146, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cb8400;
    border-color: #be7c00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 146, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #c42527;
  border-color: #c42527; }
  .btn-danger:hover {
    color: #fff;
    background-color: #a41f21;
    border-color: #991d1e; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #a41f21;
    border-color: #991d1e;
    box-shadow: 0 0 0 0.2rem rgba(205, 70, 71, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #c42527;
    border-color: #c42527; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #991d1e;
    border-color: #8e1b1c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(205, 70, 71, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #212529;
  background-color: #e9e0f9;
  border-color: #e9e0f9; }
  .btn-dark:hover {
    color: #212529;
    background-color: #d2c0f3;
    border-color: #cbb5f1; }
  .btn-dark:focus, .btn-dark.focus {
    color: #212529;
    background-color: #d2c0f3;
    border-color: #cbb5f1;
    box-shadow: 0 0 0 0.2rem rgba(203, 196, 218, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #212529;
    background-color: #e9e0f9;
    border-color: #e9e0f9; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #212529;
    background-color: #cbb5f1;
    border-color: #c3abef; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 196, 218, 0.5); }

.btn-theme {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-theme:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-theme:focus, .btn-theme.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-theme.disabled, .btn-theme:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-theme:not(:disabled):not(.disabled):active, .btn-theme:not(:disabled):not(.disabled).active,
  .show > .btn-theme.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-theme:not(:disabled):not(.disabled):active:focus, .btn-theme:not(:disabled):not(.disabled).active:focus,
    .show > .btn-theme.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-black {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-mdblue {
  color: #fff;
  background-color: #0a3782;
  border-color: #0a3782; }
  .btn-mdblue:hover {
    color: #fff;
    background-color: #07285e;
    border-color: #062353; }
  .btn-mdblue:focus, .btn-mdblue.focus {
    color: #fff;
    background-color: #07285e;
    border-color: #062353;
    box-shadow: 0 0 0 0.2rem rgba(47, 85, 149, 0.5); }
  .btn-mdblue.disabled, .btn-mdblue:disabled {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
  .btn-mdblue:not(:disabled):not(.disabled):active, .btn-mdblue:not(:disabled):not(.disabled).active,
  .show > .btn-mdblue.dropdown-toggle {
    color: #fff;
    background-color: #062353;
    border-color: #051e47; }
    .btn-mdblue:not(:disabled):not(.disabled):active:focus, .btn-mdblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-mdblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 85, 149, 0.5); }

.btn-mdorange {
  color: #fff;
  background-color: #ff7700;
  border-color: #ff7700; }
  .btn-mdorange:hover {
    color: #fff;
    background-color: #d96500;
    border-color: #cc5f00; }
  .btn-mdorange:focus, .btn-mdorange.focus {
    color: #fff;
    background-color: #d96500;
    border-color: #cc5f00;
    box-shadow: 0 0 0 0.2rem rgba(255, 139, 38, 0.5); }
  .btn-mdorange.disabled, .btn-mdorange:disabled {
    color: #fff;
    background-color: #ff7700;
    border-color: #ff7700; }
  .btn-mdorange:not(:disabled):not(.disabled):active, .btn-mdorange:not(:disabled):not(.disabled).active,
  .show > .btn-mdorange.dropdown-toggle {
    color: #fff;
    background-color: #cc5f00;
    border-color: #bf5900; }
    .btn-mdorange:not(:disabled):not(.disabled):active:focus, .btn-mdorange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-mdorange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 139, 38, 0.5); }

.btn-mdgreen {
  color: #fff;
  background-color: #129145;
  border-color: #129145; }
  .btn-mdgreen:hover {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f; }
  .btn-mdgreen:focus, .btn-mdgreen.focus {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f;
    box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }
  .btn-mdgreen.disabled, .btn-mdgreen:disabled {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-mdgreen:not(:disabled):not(.disabled):active, .btn-mdgreen:not(:disabled):not(.disabled).active,
  .show > .btn-mdgreen.dropdown-toggle {
    color: #fff;
    background-color: #0c642f;
    border-color: #0b582a; }
    .btn-mdgreen:not(:disabled):not(.disabled):active:focus, .btn-mdgreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-mdgreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }

.btn-uigray500 {
  color: #fff;
  background-color: #63666a;
  border-color: #63666a; }
  .btn-uigray500:hover {
    color: #fff;
    background-color: #515356;
    border-color: #4a4d50; }
  .btn-uigray500:focus, .btn-uigray500.focus {
    color: #fff;
    background-color: #515356;
    border-color: #4a4d50;
    box-shadow: 0 0 0 0.2rem rgba(122, 125, 128, 0.5); }
  .btn-uigray500.disabled, .btn-uigray500:disabled {
    color: #fff;
    background-color: #63666a;
    border-color: #63666a; }
  .btn-uigray500:not(:disabled):not(.disabled):active, .btn-uigray500:not(:disabled):not(.disabled).active,
  .show > .btn-uigray500.dropdown-toggle {
    color: #fff;
    background-color: #4a4d50;
    border-color: #444649; }
    .btn-uigray500:not(:disabled):not(.disabled):active:focus, .btn-uigray500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uigray500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(122, 125, 128, 0.5); }

.btn-uiblue700 {
  color: #fff;
  background-color: #010e23;
  border-color: #010e23; }
  .btn-uiblue700:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-uiblue700:focus, .btn-uiblue700.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(39, 50, 68, 0.5); }
  .btn-uiblue700.disabled, .btn-uiblue700:disabled {
    color: #fff;
    background-color: #010e23;
    border-color: #010e23; }
  .btn-uiblue700:not(:disabled):not(.disabled):active, .btn-uiblue700:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue700.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-uiblue700:not(:disabled):not(.disabled):active:focus, .btn-uiblue700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 50, 68, 0.5); }

.btn-uiblue600 {
  color: #fff;
  background-color: #041b43;
  border-color: #041b43; }
  .btn-uiblue600:hover {
    color: #fff;
    background-color: #020c1f;
    border-color: #010813; }
  .btn-uiblue600:focus, .btn-uiblue600.focus {
    color: #fff;
    background-color: #020c1f;
    border-color: #010813;
    box-shadow: 0 0 0 0.2rem rgba(42, 61, 95, 0.5); }
  .btn-uiblue600.disabled, .btn-uiblue600:disabled {
    color: #fff;
    background-color: #041b43;
    border-color: #041b43; }
  .btn-uiblue600:not(:disabled):not(.disabled):active, .btn-uiblue600:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue600.dropdown-toggle {
    color: #fff;
    background-color: #010813;
    border-color: #000307; }
    .btn-uiblue600:not(:disabled):not(.disabled):active:focus, .btn-uiblue600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 61, 95, 0.5); }

.btn-uiblue500 {
  color: #fff;
  background-color: #0a3782;
  border-color: #0a3782; }
  .btn-uiblue500:hover {
    color: #fff;
    background-color: #07285e;
    border-color: #062353; }
  .btn-uiblue500:focus, .btn-uiblue500.focus {
    color: #fff;
    background-color: #07285e;
    border-color: #062353;
    box-shadow: 0 0 0 0.2rem rgba(47, 85, 149, 0.5); }
  .btn-uiblue500.disabled, .btn-uiblue500:disabled {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
  .btn-uiblue500:not(:disabled):not(.disabled):active, .btn-uiblue500:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue500.dropdown-toggle {
    color: #fff;
    background-color: #062353;
    border-color: #051e47; }
    .btn-uiblue500:not(:disabled):not(.disabled):active:focus, .btn-uiblue500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 85, 149, 0.5); }

.btn-uiblue400 {
  color: #fff;
  background-color: #325796;
  border-color: #325796; }
  .btn-uiblue400:hover {
    color: #fff;
    background-color: #284679;
    border-color: #254170; }
  .btn-uiblue400:focus, .btn-uiblue400.focus {
    color: #fff;
    background-color: #284679;
    border-color: #254170;
    box-shadow: 0 0 0 0.2rem rgba(81, 112, 166, 0.5); }
  .btn-uiblue400.disabled, .btn-uiblue400:disabled {
    color: #fff;
    background-color: #325796;
    border-color: #325796; }
  .btn-uiblue400:not(:disabled):not(.disabled):active, .btn-uiblue400:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue400.dropdown-toggle {
    color: #fff;
    background-color: #254170;
    border-color: #223b66; }
    .btn-uiblue400:not(:disabled):not(.disabled):active:focus, .btn-uiblue400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 112, 166, 0.5); }

.btn-uiblue300 {
  color: #fff;
  background-color: #637faf;
  border-color: #637faf; }
  .btn-uiblue300:hover {
    color: #fff;
    background-color: #506c9c;
    border-color: #4c6693; }
  .btn-uiblue300:focus, .btn-uiblue300.focus {
    color: #fff;
    background-color: #506c9c;
    border-color: #4c6693;
    box-shadow: 0 0 0 0.2rem rgba(122, 146, 187, 0.5); }
  .btn-uiblue300.disabled, .btn-uiblue300:disabled {
    color: #fff;
    background-color: #637faf;
    border-color: #637faf; }
  .btn-uiblue300:not(:disabled):not(.disabled):active, .btn-uiblue300:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue300.dropdown-toggle {
    color: #fff;
    background-color: #4c6693;
    border-color: #47608b; }
    .btn-uiblue300:not(:disabled):not(.disabled):active:focus, .btn-uiblue300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(122, 146, 187, 0.5); }

.btn-uiblue200 {
  color: #212529;
  background-color: #97aaca;
  border-color: #97aaca; }
  .btn-uiblue200:hover {
    color: #fff;
    background-color: #7e95bd;
    border-color: #758eb9; }
  .btn-uiblue200:focus, .btn-uiblue200.focus {
    color: #fff;
    background-color: #7e95bd;
    border-color: #758eb9;
    box-shadow: 0 0 0 0.2rem rgba(133, 150, 178, 0.5); }
  .btn-uiblue200.disabled, .btn-uiblue200:disabled {
    color: #212529;
    background-color: #97aaca;
    border-color: #97aaca; }
  .btn-uiblue200:not(:disabled):not(.disabled):active, .btn-uiblue200:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue200.dropdown-toggle {
    color: #fff;
    background-color: #758eb9;
    border-color: #6d87b4; }
    .btn-uiblue200:not(:disabled):not(.disabled):active:focus, .btn-uiblue200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 150, 178, 0.5); }

.btn-uiblue100 {
  color: #212529;
  background-color: #c5d0e1;
  border-color: #c5d0e1; }
  .btn-uiblue100:hover {
    color: #212529;
    background-color: #acbcd4;
    border-color: #a3b5d0; }
  .btn-uiblue100:focus, .btn-uiblue100.focus {
    color: #212529;
    background-color: #acbcd4;
    border-color: #a3b5d0;
    box-shadow: 0 0 0 0.2rem rgba(172, 182, 197, 0.5); }
  .btn-uiblue100.disabled, .btn-uiblue100:disabled {
    color: #212529;
    background-color: #c5d0e1;
    border-color: #c5d0e1; }
  .btn-uiblue100:not(:disabled):not(.disabled):active, .btn-uiblue100:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue100.dropdown-toggle {
    color: #212529;
    background-color: #a3b5d0;
    border-color: #9baecb; }
    .btn-uiblue100:not(:disabled):not(.disabled):active:focus, .btn-uiblue100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(172, 182, 197, 0.5); }

.btn-uiblue20 {
  color: #212529;
  background-color: #eef1f6;
  border-color: #eef1f6; }
  .btn-uiblue20:hover {
    color: #212529;
    background-color: #d5dce9;
    border-color: #cdd6e4; }
  .btn-uiblue20:focus, .btn-uiblue20.focus {
    color: #212529;
    background-color: #d5dce9;
    border-color: #cdd6e4;
    box-shadow: 0 0 0 0.2rem rgba(207, 210, 215, 0.5); }
  .btn-uiblue20.disabled, .btn-uiblue20:disabled {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
  .btn-uiblue20:not(:disabled):not(.disabled):active, .btn-uiblue20:not(:disabled):not(.disabled).active,
  .show > .btn-uiblue20.dropdown-toggle {
    color: #212529;
    background-color: #cdd6e4;
    border-color: #c4cfe0; }
    .btn-uiblue20:not(:disabled):not(.disabled):active:focus, .btn-uiblue20:not(:disabled):not(.disabled).active:focus,
    .show > .btn-uiblue20.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 210, 215, 0.5); }

.btn-accentyellow {
  color: #212529;
  background-color: #ffbf00;
  border-color: #ffbf00; }
  .btn-accentyellow:hover {
    color: #212529;
    background-color: #d9a200;
    border-color: #cc9900; }
  .btn-accentyellow:focus, .btn-accentyellow.focus {
    color: #212529;
    background-color: #d9a200;
    border-color: #cc9900;
    box-shadow: 0 0 0 0.2rem rgba(222, 168, 6, 0.5); }
  .btn-accentyellow.disabled, .btn-accentyellow:disabled {
    color: #212529;
    background-color: #ffbf00;
    border-color: #ffbf00; }
  .btn-accentyellow:not(:disabled):not(.disabled):active, .btn-accentyellow:not(:disabled):not(.disabled).active,
  .show > .btn-accentyellow.dropdown-toggle {
    color: #212529;
    background-color: #cc9900;
    border-color: #bf8f00; }
    .btn-accentyellow:not(:disabled):not(.disabled):active:focus, .btn-accentyellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentyellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 168, 6, 0.5); }

.btn-accentpink {
  color: #fff;
  background-color: #e83f6f;
  border-color: #e83f6f; }
  .btn-accentpink:hover {
    color: #fff;
    background-color: #e41d55;
    border-color: #da1a51; }
  .btn-accentpink:focus, .btn-accentpink.focus {
    color: #fff;
    background-color: #e41d55;
    border-color: #da1a51;
    box-shadow: 0 0 0 0.2rem rgba(235, 92, 133, 0.5); }
  .btn-accentpink.disabled, .btn-accentpink:disabled {
    color: #fff;
    background-color: #e83f6f;
    border-color: #e83f6f; }
  .btn-accentpink:not(:disabled):not(.disabled):active, .btn-accentpink:not(:disabled):not(.disabled).active,
  .show > .btn-accentpink.dropdown-toggle {
    color: #fff;
    background-color: #da1a51;
    border-color: #cf194c; }
    .btn-accentpink:not(:disabled):not(.disabled):active:focus, .btn-accentpink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentpink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 92, 133, 0.5); }

.btn-accentblue {
  color: #fff;
  background-color: #2174a5;
  border-color: #2174a5; }
  .btn-accentblue:hover {
    color: #fff;
    background-color: #1b5e85;
    border-color: #19567b; }
  .btn-accentblue:focus, .btn-accentblue.focus {
    color: #fff;
    background-color: #1b5e85;
    border-color: #19567b;
    box-shadow: 0 0 0 0.2rem rgba(66, 137, 179, 0.5); }
  .btn-accentblue.disabled, .btn-accentblue:disabled {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
  .btn-accentblue:not(:disabled):not(.disabled):active, .btn-accentblue:not(:disabled):not(.disabled).active,
  .show > .btn-accentblue.dropdown-toggle {
    color: #fff;
    background-color: #19567b;
    border-color: #164f70; }
    .btn-accentblue:not(:disabled):not(.disabled):active:focus, .btn-accentblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 137, 179, 0.5); }

.btn-accentlightblue {
  color: #fff;
  background-color: #0077e2;
  border-color: #0077e2; }
  .btn-accentlightblue:hover {
    color: #fff;
    background-color: #0063bc;
    border-color: #005caf; }
  .btn-accentlightblue:focus, .btn-accentlightblue.focus {
    color: #fff;
    background-color: #0063bc;
    border-color: #005caf;
    box-shadow: 0 0 0 0.2rem rgba(38, 139, 230, 0.5); }
  .btn-accentlightblue.disabled, .btn-accentlightblue:disabled {
    color: #fff;
    background-color: #0077e2;
    border-color: #0077e2; }
  .btn-accentlightblue:not(:disabled):not(.disabled):active, .btn-accentlightblue:not(:disabled):not(.disabled).active,
  .show > .btn-accentlightblue.dropdown-toggle {
    color: #fff;
    background-color: #005caf;
    border-color: #0055a2; }
    .btn-accentlightblue:not(:disabled):not(.disabled):active:focus, .btn-accentlightblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentlightblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 139, 230, 0.5); }

.btn-accentgreen {
  color: #fff;
  background-color: #129145;
  border-color: #129145; }
  .btn-accentgreen:hover {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f; }
  .btn-accentgreen:focus, .btn-accentgreen.focus {
    color: #fff;
    background-color: #0e6f35;
    border-color: #0c642f;
    box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }
  .btn-accentgreen.disabled, .btn-accentgreen:disabled {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-accentgreen:not(:disabled):not(.disabled):active, .btn-accentgreen:not(:disabled):not(.disabled).active,
  .show > .btn-accentgreen.dropdown-toggle {
    color: #fff;
    background-color: #0c642f;
    border-color: #0b582a; }
    .btn-accentgreen:not(:disabled):not(.disabled):active:focus, .btn-accentgreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentgreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 162, 97, 0.5); }

.btn-accentgrey {
  color: #212529;
  background-color: #f4f8f9;
  border-color: #f4f8f9; }
  .btn-accentgrey:hover {
    color: #212529;
    background-color: #dbe8ec;
    border-color: #d3e3e7; }
  .btn-accentgrey:focus, .btn-accentgrey.focus {
    color: #212529;
    background-color: #dbe8ec;
    border-color: #d3e3e7;
    box-shadow: 0 0 0 0.2rem rgba(212, 216, 218, 0.5); }
  .btn-accentgrey.disabled, .btn-accentgrey:disabled {
    color: #212529;
    background-color: #f4f8f9;
    border-color: #f4f8f9; }
  .btn-accentgrey:not(:disabled):not(.disabled):active, .btn-accentgrey:not(:disabled):not(.disabled).active,
  .show > .btn-accentgrey.dropdown-toggle {
    color: #212529;
    background-color: #d3e3e7;
    border-color: #cbdee3; }
    .btn-accentgrey:not(:disabled):not(.disabled):active:focus, .btn-accentgrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accentgrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 216, 218, 0.5); }

.btn-urgent {
  color: #fff;
  background-color: #b2140a;
  border-color: #b2140a; }
  .btn-urgent:hover {
    color: #fff;
    background-color: #8e1008;
    border-color: #820f07; }
  .btn-urgent:focus, .btn-urgent.focus {
    color: #fff;
    background-color: #8e1008;
    border-color: #820f07;
    box-shadow: 0 0 0 0.2rem rgba(190, 55, 47, 0.5); }
  .btn-urgent.disabled, .btn-urgent:disabled {
    color: #fff;
    background-color: #b2140a;
    border-color: #b2140a; }
  .btn-urgent:not(:disabled):not(.disabled):active, .btn-urgent:not(:disabled):not(.disabled).active,
  .show > .btn-urgent.dropdown-toggle {
    color: #fff;
    background-color: #820f07;
    border-color: #760d07; }
    .btn-urgent:not(:disabled):not(.disabled):active:focus, .btn-urgent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-urgent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 55, 47, 0.5); }

.btn-pressing {
  color: #212529;
  background-color: #fea500;
  border-color: #fea500; }
  .btn-pressing:hover {
    color: #fff;
    background-color: #d88c00;
    border-color: #cb8400; }
  .btn-pressing:focus, .btn-pressing.focus {
    color: #fff;
    background-color: #d88c00;
    border-color: #cb8400;
    box-shadow: 0 0 0 0.2rem rgba(221, 146, 6, 0.5); }
  .btn-pressing.disabled, .btn-pressing:disabled {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-pressing:not(:disabled):not(.disabled):active, .btn-pressing:not(:disabled):not(.disabled).active,
  .show > .btn-pressing.dropdown-toggle {
    color: #fff;
    background-color: #cb8400;
    border-color: #be7c00; }
    .btn-pressing:not(:disabled):not(.disabled):active:focus, .btn-pressing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pressing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 146, 6, 0.5); }

.btn-cosmetic {
  color: #fff;
  background-color: #003782;
  border-color: #003782; }
  .btn-cosmetic:hover {
    color: #fff;
    background-color: #00275c;
    border-color: #00214f; }
  .btn-cosmetic:focus, .btn-cosmetic.focus {
    color: #fff;
    background-color: #00275c;
    border-color: #00214f;
    box-shadow: 0 0 0 0.2rem rgba(38, 85, 149, 0.5); }
  .btn-cosmetic.disabled, .btn-cosmetic:disabled {
    color: #fff;
    background-color: #003782;
    border-color: #003782; }
  .btn-cosmetic:not(:disabled):not(.disabled):active, .btn-cosmetic:not(:disabled):not(.disabled).active,
  .show > .btn-cosmetic.dropdown-toggle {
    color: #fff;
    background-color: #00214f;
    border-color: #001c42; }
    .btn-cosmetic:not(:disabled):not(.disabled):active:focus, .btn-cosmetic:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cosmetic.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 85, 149, 0.5); }

.btn-outline-primary {
  color: #8560a7;
  border-color: #8560a7; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #8560a7;
    border-color: #8560a7; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #8560a7;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #8560a7;
    border-color: #8560a7; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.5); }

.btn-outline-secondary {
  color: #eef1f6;
  border-color: #eef1f6; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #eef1f6;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }

.btn-outline-success {
  color: #129145;
  border-color: #129145; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #129145;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.btn-outline-info {
  color: #2174a5;
  border-color: #2174a5; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2174a5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }

.btn-outline-warning {
  color: #fea500;
  border-color: #fea500; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fea500;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }

.btn-outline-danger {
  color: #c42527;
  border-color: #c42527; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #c42527;
    border-color: #c42527; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c42527;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #c42527;
    border-color: #c42527; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #e9e0f9;
  border-color: #e9e0f9; }
  .btn-outline-dark:hover {
    color: #212529;
    background-color: #e9e0f9;
    border-color: #e9e0f9; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 224, 249, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #e9e0f9;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #e9e0f9;
    border-color: #e9e0f9; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 224, 249, 0.5); }

.btn-outline-theme {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-theme:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-theme:focus, .btn-outline-theme.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-theme.disabled, .btn-outline-theme:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-theme:not(:disabled):not(.disabled):active, .btn-outline-theme:not(:disabled):not(.disabled).active,
  .show > .btn-outline-theme.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-theme:not(:disabled):not(.disabled):active:focus, .btn-outline-theme:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-theme.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-black {
  color: #000000;
  border-color: #000000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000000;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-mdblue {
  color: #0a3782;
  border-color: #0a3782; }
  .btn-outline-mdblue:hover {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
  .btn-outline-mdblue:focus, .btn-outline-mdblue.focus {
    box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }
  .btn-outline-mdblue.disabled, .btn-outline-mdblue:disabled {
    color: #0a3782;
    background-color: transparent; }
  .btn-outline-mdblue:not(:disabled):not(.disabled):active, .btn-outline-mdblue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-mdblue.dropdown-toggle {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
    .btn-outline-mdblue:not(:disabled):not(.disabled):active:focus, .btn-outline-mdblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-mdblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }

.btn-outline-mdorange {
  color: #ff7700;
  border-color: #ff7700; }
  .btn-outline-mdorange:hover {
    color: #fff;
    background-color: #ff7700;
    border-color: #ff7700; }
  .btn-outline-mdorange:focus, .btn-outline-mdorange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 0, 0.5); }
  .btn-outline-mdorange.disabled, .btn-outline-mdorange:disabled {
    color: #ff7700;
    background-color: transparent; }
  .btn-outline-mdorange:not(:disabled):not(.disabled):active, .btn-outline-mdorange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-mdorange.dropdown-toggle {
    color: #fff;
    background-color: #ff7700;
    border-color: #ff7700; }
    .btn-outline-mdorange:not(:disabled):not(.disabled):active:focus, .btn-outline-mdorange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-mdorange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 119, 0, 0.5); }

.btn-outline-mdgreen {
  color: #129145;
  border-color: #129145; }
  .btn-outline-mdgreen:hover {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-outline-mdgreen:focus, .btn-outline-mdgreen.focus {
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }
  .btn-outline-mdgreen.disabled, .btn-outline-mdgreen:disabled {
    color: #129145;
    background-color: transparent; }
  .btn-outline-mdgreen:not(:disabled):not(.disabled):active, .btn-outline-mdgreen:not(:disabled):not(.disabled).active,
  .show > .btn-outline-mdgreen.dropdown-toggle {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
    .btn-outline-mdgreen:not(:disabled):not(.disabled):active:focus, .btn-outline-mdgreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-mdgreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.btn-outline-uigray500 {
  color: #63666a;
  border-color: #63666a; }
  .btn-outline-uigray500:hover {
    color: #fff;
    background-color: #63666a;
    border-color: #63666a; }
  .btn-outline-uigray500:focus, .btn-outline-uigray500.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5); }
  .btn-outline-uigray500.disabled, .btn-outline-uigray500:disabled {
    color: #63666a;
    background-color: transparent; }
  .btn-outline-uigray500:not(:disabled):not(.disabled):active, .btn-outline-uigray500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uigray500.dropdown-toggle {
    color: #fff;
    background-color: #63666a;
    border-color: #63666a; }
    .btn-outline-uigray500:not(:disabled):not(.disabled):active:focus, .btn-outline-uigray500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uigray500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5); }

.btn-outline-uiblue700 {
  color: #010e23;
  border-color: #010e23; }
  .btn-outline-uiblue700:hover {
    color: #fff;
    background-color: #010e23;
    border-color: #010e23; }
  .btn-outline-uiblue700:focus, .btn-outline-uiblue700.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 14, 35, 0.5); }
  .btn-outline-uiblue700.disabled, .btn-outline-uiblue700:disabled {
    color: #010e23;
    background-color: transparent; }
  .btn-outline-uiblue700:not(:disabled):not(.disabled):active, .btn-outline-uiblue700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue700.dropdown-toggle {
    color: #fff;
    background-color: #010e23;
    border-color: #010e23; }
    .btn-outline-uiblue700:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 14, 35, 0.5); }

.btn-outline-uiblue600 {
  color: #041b43;
  border-color: #041b43; }
  .btn-outline-uiblue600:hover {
    color: #fff;
    background-color: #041b43;
    border-color: #041b43; }
  .btn-outline-uiblue600:focus, .btn-outline-uiblue600.focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 27, 67, 0.5); }
  .btn-outline-uiblue600.disabled, .btn-outline-uiblue600:disabled {
    color: #041b43;
    background-color: transparent; }
  .btn-outline-uiblue600:not(:disabled):not(.disabled):active, .btn-outline-uiblue600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue600.dropdown-toggle {
    color: #fff;
    background-color: #041b43;
    border-color: #041b43; }
    .btn-outline-uiblue600:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(4, 27, 67, 0.5); }

.btn-outline-uiblue500 {
  color: #0a3782;
  border-color: #0a3782; }
  .btn-outline-uiblue500:hover {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
  .btn-outline-uiblue500:focus, .btn-outline-uiblue500.focus {
    box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }
  .btn-outline-uiblue500.disabled, .btn-outline-uiblue500:disabled {
    color: #0a3782;
    background-color: transparent; }
  .btn-outline-uiblue500:not(:disabled):not(.disabled):active, .btn-outline-uiblue500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue500.dropdown-toggle {
    color: #fff;
    background-color: #0a3782;
    border-color: #0a3782; }
    .btn-outline-uiblue500:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }

.btn-outline-uiblue400 {
  color: #325796;
  border-color: #325796; }
  .btn-outline-uiblue400:hover {
    color: #fff;
    background-color: #325796;
    border-color: #325796; }
  .btn-outline-uiblue400:focus, .btn-outline-uiblue400.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 87, 150, 0.5); }
  .btn-outline-uiblue400.disabled, .btn-outline-uiblue400:disabled {
    color: #325796;
    background-color: transparent; }
  .btn-outline-uiblue400:not(:disabled):not(.disabled):active, .btn-outline-uiblue400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue400.dropdown-toggle {
    color: #fff;
    background-color: #325796;
    border-color: #325796; }
    .btn-outline-uiblue400:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 87, 150, 0.5); }

.btn-outline-uiblue300 {
  color: #637faf;
  border-color: #637faf; }
  .btn-outline-uiblue300:hover {
    color: #fff;
    background-color: #637faf;
    border-color: #637faf; }
  .btn-outline-uiblue300:focus, .btn-outline-uiblue300.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 127, 175, 0.5); }
  .btn-outline-uiblue300.disabled, .btn-outline-uiblue300:disabled {
    color: #637faf;
    background-color: transparent; }
  .btn-outline-uiblue300:not(:disabled):not(.disabled):active, .btn-outline-uiblue300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue300.dropdown-toggle {
    color: #fff;
    background-color: #637faf;
    border-color: #637faf; }
    .btn-outline-uiblue300:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 127, 175, 0.5); }

.btn-outline-uiblue200 {
  color: #97aaca;
  border-color: #97aaca; }
  .btn-outline-uiblue200:hover {
    color: #212529;
    background-color: #97aaca;
    border-color: #97aaca; }
  .btn-outline-uiblue200:focus, .btn-outline-uiblue200.focus {
    box-shadow: 0 0 0 0.2rem rgba(151, 170, 202, 0.5); }
  .btn-outline-uiblue200.disabled, .btn-outline-uiblue200:disabled {
    color: #97aaca;
    background-color: transparent; }
  .btn-outline-uiblue200:not(:disabled):not(.disabled):active, .btn-outline-uiblue200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue200.dropdown-toggle {
    color: #212529;
    background-color: #97aaca;
    border-color: #97aaca; }
    .btn-outline-uiblue200:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(151, 170, 202, 0.5); }

.btn-outline-uiblue100 {
  color: #c5d0e1;
  border-color: #c5d0e1; }
  .btn-outline-uiblue100:hover {
    color: #212529;
    background-color: #c5d0e1;
    border-color: #c5d0e1; }
  .btn-outline-uiblue100:focus, .btn-outline-uiblue100.focus {
    box-shadow: 0 0 0 0.2rem rgba(197, 208, 225, 0.5); }
  .btn-outline-uiblue100.disabled, .btn-outline-uiblue100:disabled {
    color: #c5d0e1;
    background-color: transparent; }
  .btn-outline-uiblue100:not(:disabled):not(.disabled):active, .btn-outline-uiblue100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue100.dropdown-toggle {
    color: #212529;
    background-color: #c5d0e1;
    border-color: #c5d0e1; }
    .btn-outline-uiblue100:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(197, 208, 225, 0.5); }

.btn-outline-uiblue20 {
  color: #eef1f6;
  border-color: #eef1f6; }
  .btn-outline-uiblue20:hover {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
  .btn-outline-uiblue20:focus, .btn-outline-uiblue20.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }
  .btn-outline-uiblue20.disabled, .btn-outline-uiblue20:disabled {
    color: #eef1f6;
    background-color: transparent; }
  .btn-outline-uiblue20:not(:disabled):not(.disabled):active, .btn-outline-uiblue20:not(:disabled):not(.disabled).active,
  .show > .btn-outline-uiblue20.dropdown-toggle {
    color: #212529;
    background-color: #eef1f6;
    border-color: #eef1f6; }
    .btn-outline-uiblue20:not(:disabled):not(.disabled):active:focus, .btn-outline-uiblue20:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-uiblue20.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }

.btn-outline-accentyellow {
  color: #ffbf00;
  border-color: #ffbf00; }
  .btn-outline-accentyellow:hover {
    color: #212529;
    background-color: #ffbf00;
    border-color: #ffbf00; }
  .btn-outline-accentyellow:focus, .btn-outline-accentyellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 191, 0, 0.5); }
  .btn-outline-accentyellow.disabled, .btn-outline-accentyellow:disabled {
    color: #ffbf00;
    background-color: transparent; }
  .btn-outline-accentyellow:not(:disabled):not(.disabled):active, .btn-outline-accentyellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentyellow.dropdown-toggle {
    color: #212529;
    background-color: #ffbf00;
    border-color: #ffbf00; }
    .btn-outline-accentyellow:not(:disabled):not(.disabled):active:focus, .btn-outline-accentyellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentyellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 191, 0, 0.5); }

.btn-outline-accentpink {
  color: #e83f6f;
  border-color: #e83f6f; }
  .btn-outline-accentpink:hover {
    color: #fff;
    background-color: #e83f6f;
    border-color: #e83f6f; }
  .btn-outline-accentpink:focus, .btn-outline-accentpink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 63, 111, 0.5); }
  .btn-outline-accentpink.disabled, .btn-outline-accentpink:disabled {
    color: #e83f6f;
    background-color: transparent; }
  .btn-outline-accentpink:not(:disabled):not(.disabled):active, .btn-outline-accentpink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentpink.dropdown-toggle {
    color: #fff;
    background-color: #e83f6f;
    border-color: #e83f6f; }
    .btn-outline-accentpink:not(:disabled):not(.disabled):active:focus, .btn-outline-accentpink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentpink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 63, 111, 0.5); }

.btn-outline-accentblue {
  color: #2174a5;
  border-color: #2174a5; }
  .btn-outline-accentblue:hover {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
  .btn-outline-accentblue:focus, .btn-outline-accentblue.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }
  .btn-outline-accentblue.disabled, .btn-outline-accentblue:disabled {
    color: #2174a5;
    background-color: transparent; }
  .btn-outline-accentblue:not(:disabled):not(.disabled):active, .btn-outline-accentblue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentblue.dropdown-toggle {
    color: #fff;
    background-color: #2174a5;
    border-color: #2174a5; }
    .btn-outline-accentblue:not(:disabled):not(.disabled):active:focus, .btn-outline-accentblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }

.btn-outline-accentlightblue {
  color: #0077e2;
  border-color: #0077e2; }
  .btn-outline-accentlightblue:hover {
    color: #fff;
    background-color: #0077e2;
    border-color: #0077e2; }
  .btn-outline-accentlightblue:focus, .btn-outline-accentlightblue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 226, 0.5); }
  .btn-outline-accentlightblue.disabled, .btn-outline-accentlightblue:disabled {
    color: #0077e2;
    background-color: transparent; }
  .btn-outline-accentlightblue:not(:disabled):not(.disabled):active, .btn-outline-accentlightblue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentlightblue.dropdown-toggle {
    color: #fff;
    background-color: #0077e2;
    border-color: #0077e2; }
    .btn-outline-accentlightblue:not(:disabled):not(.disabled):active:focus, .btn-outline-accentlightblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentlightblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 119, 226, 0.5); }

.btn-outline-accentgreen {
  color: #129145;
  border-color: #129145; }
  .btn-outline-accentgreen:hover {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
  .btn-outline-accentgreen:focus, .btn-outline-accentgreen.focus {
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }
  .btn-outline-accentgreen.disabled, .btn-outline-accentgreen:disabled {
    color: #129145;
    background-color: transparent; }
  .btn-outline-accentgreen:not(:disabled):not(.disabled):active, .btn-outline-accentgreen:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentgreen.dropdown-toggle {
    color: #fff;
    background-color: #129145;
    border-color: #129145; }
    .btn-outline-accentgreen:not(:disabled):not(.disabled):active:focus, .btn-outline-accentgreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentgreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.btn-outline-accentgrey {
  color: #f4f8f9;
  border-color: #f4f8f9; }
  .btn-outline-accentgrey:hover {
    color: #212529;
    background-color: #f4f8f9;
    border-color: #f4f8f9; }
  .btn-outline-accentgrey:focus, .btn-outline-accentgrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 248, 249, 0.5); }
  .btn-outline-accentgrey.disabled, .btn-outline-accentgrey:disabled {
    color: #f4f8f9;
    background-color: transparent; }
  .btn-outline-accentgrey:not(:disabled):not(.disabled):active, .btn-outline-accentgrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accentgrey.dropdown-toggle {
    color: #212529;
    background-color: #f4f8f9;
    border-color: #f4f8f9; }
    .btn-outline-accentgrey:not(:disabled):not(.disabled):active:focus, .btn-outline-accentgrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accentgrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 248, 249, 0.5); }

.btn-outline-urgent {
  color: #b2140a;
  border-color: #b2140a; }
  .btn-outline-urgent:hover {
    color: #fff;
    background-color: #b2140a;
    border-color: #b2140a; }
  .btn-outline-urgent:focus, .btn-outline-urgent.focus {
    box-shadow: 0 0 0 0.2rem rgba(178, 20, 10, 0.5); }
  .btn-outline-urgent.disabled, .btn-outline-urgent:disabled {
    color: #b2140a;
    background-color: transparent; }
  .btn-outline-urgent:not(:disabled):not(.disabled):active, .btn-outline-urgent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-urgent.dropdown-toggle {
    color: #fff;
    background-color: #b2140a;
    border-color: #b2140a; }
    .btn-outline-urgent:not(:disabled):not(.disabled):active:focus, .btn-outline-urgent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-urgent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 20, 10, 0.5); }

.btn-outline-pressing {
  color: #fea500;
  border-color: #fea500; }
  .btn-outline-pressing:hover {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-outline-pressing:focus, .btn-outline-pressing.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }
  .btn-outline-pressing.disabled, .btn-outline-pressing:disabled {
    color: #fea500;
    background-color: transparent; }
  .btn-outline-pressing:not(:disabled):not(.disabled):active, .btn-outline-pressing:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pressing.dropdown-toggle {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
    .btn-outline-pressing:not(:disabled):not(.disabled):active:focus, .btn-outline-pressing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pressing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }

.btn-outline-cosmetic {
  color: #003782;
  border-color: #003782; }
  .btn-outline-cosmetic:hover {
    color: #fff;
    background-color: #003782;
    border-color: #003782; }
  .btn-outline-cosmetic:focus, .btn-outline-cosmetic.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 55, 130, 0.5); }
  .btn-outline-cosmetic.disabled, .btn-outline-cosmetic:disabled {
    color: #003782;
    background-color: transparent; }
  .btn-outline-cosmetic:not(:disabled):not(.disabled):active, .btn-outline-cosmetic:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cosmetic.dropdown-toggle {
    color: #fff;
    background-color: #003782;
    border-color: #003782; }
    .btn-outline-cosmetic:not(:disabled):not(.disabled):active:focus, .btn-outline-cosmetic:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cosmetic.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 55, 130, 0.5); }

.btn-link {
  font-weight: 400;
  color: #8560a7;
  text-decoration: none; }
  .btn-link:hover {
    color: #5e4278;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #8560a7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #8560a7;
    background-color: #8560a7; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #c4b2d4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #ddd3e7;
    border-color: #ddd3e7; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #8560a7;
  background-color: #8560a7; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(133, 96, 167, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(133, 96, 167, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(133, 96, 167, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(133, 96, 167, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #c4b2d4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #c4b2d4;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #8560a7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #ddd3e7; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #8560a7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #ddd3e7; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #8560a7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #ddd3e7; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #8560a7; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8560a7;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #5e4278;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #8560a7;
  border-color: #8560a7; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #8560a7; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #6b4c88; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 96, 167, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #eef1f6; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #cdd6e4; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }

.badge-success {
  color: #fff;
  background-color: #129145; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #0c642f; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2174a5; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #19567b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #fea500; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cb8400; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #c42527; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #991d1e; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(196, 37, 39, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #212529;
  background-color: #e9e0f9; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #212529;
    background-color: #cbb5f1; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 224, 249, 0.5); }

.badge-theme {
  color: #212529;
  background-color: #ffffff; }
  a.badge-theme:hover, a.badge-theme:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-theme:focus, a.badge-theme.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-black {
  color: #fff;
  background-color: #000000; }
  a.badge-black:hover, a.badge-black:focus {
    color: #fff;
    background-color: black; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-mdblue {
  color: #fff;
  background-color: #0a3782; }
  a.badge-mdblue:hover, a.badge-mdblue:focus {
    color: #fff;
    background-color: #062353; }
  a.badge-mdblue:focus, a.badge-mdblue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }

.badge-mdorange {
  color: #fff;
  background-color: #ff7700; }
  a.badge-mdorange:hover, a.badge-mdorange:focus {
    color: #fff;
    background-color: #cc5f00; }
  a.badge-mdorange:focus, a.badge-mdorange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 0, 0.5); }

.badge-mdgreen {
  color: #fff;
  background-color: #129145; }
  a.badge-mdgreen:hover, a.badge-mdgreen:focus {
    color: #fff;
    background-color: #0c642f; }
  a.badge-mdgreen:focus, a.badge-mdgreen.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.badge-uigray500 {
  color: #fff;
  background-color: #63666a; }
  a.badge-uigray500:hover, a.badge-uigray500:focus {
    color: #fff;
    background-color: #4a4d50; }
  a.badge-uigray500:focus, a.badge-uigray500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5); }

.badge-uiblue700 {
  color: #fff;
  background-color: #010e23; }
  a.badge-uiblue700:hover, a.badge-uiblue700:focus {
    color: #fff;
    background-color: black; }
  a.badge-uiblue700:focus, a.badge-uiblue700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 14, 35, 0.5); }

.badge-uiblue600 {
  color: #fff;
  background-color: #041b43; }
  a.badge-uiblue600:hover, a.badge-uiblue600:focus {
    color: #fff;
    background-color: #010813; }
  a.badge-uiblue600:focus, a.badge-uiblue600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 27, 67, 0.5); }

.badge-uiblue500 {
  color: #fff;
  background-color: #0a3782; }
  a.badge-uiblue500:hover, a.badge-uiblue500:focus {
    color: #fff;
    background-color: #062353; }
  a.badge-uiblue500:focus, a.badge-uiblue500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(10, 55, 130, 0.5); }

.badge-uiblue400 {
  color: #fff;
  background-color: #325796; }
  a.badge-uiblue400:hover, a.badge-uiblue400:focus {
    color: #fff;
    background-color: #254170; }
  a.badge-uiblue400:focus, a.badge-uiblue400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 87, 150, 0.5); }

.badge-uiblue300 {
  color: #fff;
  background-color: #637faf; }
  a.badge-uiblue300:hover, a.badge-uiblue300:focus {
    color: #fff;
    background-color: #4c6693; }
  a.badge-uiblue300:focus, a.badge-uiblue300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(99, 127, 175, 0.5); }

.badge-uiblue200 {
  color: #212529;
  background-color: #97aaca; }
  a.badge-uiblue200:hover, a.badge-uiblue200:focus {
    color: #212529;
    background-color: #758eb9; }
  a.badge-uiblue200:focus, a.badge-uiblue200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 170, 202, 0.5); }

.badge-uiblue100 {
  color: #212529;
  background-color: #c5d0e1; }
  a.badge-uiblue100:hover, a.badge-uiblue100:focus {
    color: #212529;
    background-color: #a3b5d0; }
  a.badge-uiblue100:focus, a.badge-uiblue100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(197, 208, 225, 0.5); }

.badge-uiblue20 {
  color: #212529;
  background-color: #eef1f6; }
  a.badge-uiblue20:hover, a.badge-uiblue20:focus {
    color: #212529;
    background-color: #cdd6e4; }
  a.badge-uiblue20:focus, a.badge-uiblue20.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 241, 246, 0.5); }

.badge-accentyellow {
  color: #212529;
  background-color: #ffbf00; }
  a.badge-accentyellow:hover, a.badge-accentyellow:focus {
    color: #212529;
    background-color: #cc9900; }
  a.badge-accentyellow:focus, a.badge-accentyellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 191, 0, 0.5); }

.badge-accentpink {
  color: #fff;
  background-color: #e83f6f; }
  a.badge-accentpink:hover, a.badge-accentpink:focus {
    color: #fff;
    background-color: #da1a51; }
  a.badge-accentpink:focus, a.badge-accentpink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 63, 111, 0.5); }

.badge-accentblue {
  color: #fff;
  background-color: #2174a5; }
  a.badge-accentblue:hover, a.badge-accentblue:focus {
    color: #fff;
    background-color: #19567b; }
  a.badge-accentblue:focus, a.badge-accentblue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 116, 165, 0.5); }

.badge-accentlightblue {
  color: #fff;
  background-color: #0077e2; }
  a.badge-accentlightblue:hover, a.badge-accentlightblue:focus {
    color: #fff;
    background-color: #005caf; }
  a.badge-accentlightblue:focus, a.badge-accentlightblue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 226, 0.5); }

.badge-accentgreen {
  color: #fff;
  background-color: #129145; }
  a.badge-accentgreen:hover, a.badge-accentgreen:focus {
    color: #fff;
    background-color: #0c642f; }
  a.badge-accentgreen:focus, a.badge-accentgreen.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 145, 69, 0.5); }

.badge-accentgrey {
  color: #212529;
  background-color: #f4f8f9; }
  a.badge-accentgrey:hover, a.badge-accentgrey:focus {
    color: #212529;
    background-color: #d3e3e7; }
  a.badge-accentgrey:focus, a.badge-accentgrey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 248, 249, 0.5); }

.badge-urgent {
  color: #fff;
  background-color: #b2140a; }
  a.badge-urgent:hover, a.badge-urgent:focus {
    color: #fff;
    background-color: #820f07; }
  a.badge-urgent:focus, a.badge-urgent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(178, 20, 10, 0.5); }

.badge-pressing {
  color: #212529;
  background-color: #fea500; }
  a.badge-pressing:hover, a.badge-pressing:focus {
    color: #212529;
    background-color: #cb8400; }
  a.badge-pressing:focus, a.badge-pressing.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 165, 0, 0.5); }

.badge-cosmetic {
  color: #fff;
  background-color: #003782; }
  a.badge-cosmetic:hover, a.badge-cosmetic:focus {
    color: #fff;
    background-color: #00214f; }
  a.badge-cosmetic:focus, a.badge-cosmetic.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 55, 130, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #453257;
  background-color: #e7dfed;
  border-color: #ddd2e6; }
  .alert-primary hr {
    border-top-color: #d1c2dd; }
  .alert-primary .alert-link {
    color: #2b1f37; }

.alert-secondary {
  color: #7c7d80;
  background-color: #fcfcfd;
  border-color: #fafbfc; }
  .alert-secondary hr {
    border-top-color: #eaeef2; }
  .alert-secondary .alert-link {
    color: #636466; }

.alert-success {
  color: #094b24;
  background-color: #d0e9da;
  border-color: #bde0cb; }
  .alert-success hr {
    border-top-color: #acd8bd; }
  .alert-success .alert-link {
    color: #041d0e; }

.alert-info {
  color: #113c56;
  background-color: #d3e3ed;
  border-color: #c1d8e6; }
  .alert-info hr {
    border-top-color: #afcddf; }
  .alert-info .alert-link {
    color: #091e2b; }

.alert-warning {
  color: #845600;
  background-color: #ffedcc;
  border-color: #ffe6b8; }
  .alert-warning hr {
    border-top-color: #ffdd9f; }
  .alert-warning .alert-link {
    color: #513500; }

.alert-danger {
  color: #661314;
  background-color: #f3d3d4;
  border-color: #eec2c3; }
  .alert-danger hr {
    border-top-color: #e8aeaf; }
  .alert-danger .alert-link {
    color: #3b0b0c; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #797481;
  background-color: #fbf9fe;
  border-color: #f9f6fd; }
  .alert-dark hr {
    border-top-color: #ebe1f8; }
  .alert-dark .alert-link {
    color: #605c66; }

.alert-theme {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-theme hr {
    border-top-color: #f2f2f2; }
  .alert-theme .alert-link {
    color: #6c6c6c; }

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-black hr {
    border-top-color: #ababab; }
  .alert-black .alert-link {
    color: black; }

.alert-mdblue {
  color: #051d44;
  background-color: #ced7e6;
  border-color: #bac7dc; }
  .alert-mdblue hr {
    border-top-color: #a9b9d3; }
  .alert-mdblue .alert-link {
    color: #020914; }

.alert-mdorange {
  color: #853e00;
  background-color: #ffe4cc;
  border-color: #ffd9b8; }
  .alert-mdorange hr {
    border-top-color: #ffcb9f; }
  .alert-mdorange .alert-link {
    color: #522600; }

.alert-mdgreen {
  color: #094b24;
  background-color: #d0e9da;
  border-color: #bde0cb; }
  .alert-mdgreen hr {
    border-top-color: #acd8bd; }
  .alert-mdgreen .alert-link {
    color: #041d0e; }

.alert-uigray500 {
  color: #333537;
  background-color: #e0e0e1;
  border-color: #d3d4d5; }
  .alert-uigray500 hr {
    border-top-color: #c6c7c9; }
  .alert-uigray500 .alert-link {
    color: #1a1c1d; }

.alert-uiblue700 {
  color: #010712;
  background-color: #cccfd3;
  border-color: #b8bcc1; }
  .alert-uiblue700 hr {
    border-top-color: #aaafb5; }
  .alert-uiblue700 .alert-link {
    color: black; }

.alert-uiblue600 {
  color: #020e23;
  background-color: #cdd1d9;
  border-color: #b9bfca; }
  .alert-uiblue600 hr {
    border-top-color: #aab2bf; }
  .alert-uiblue600 .alert-link {
    color: black; }

.alert-uiblue500 {
  color: #051d44;
  background-color: #ced7e6;
  border-color: #bac7dc; }
  .alert-uiblue500 hr {
    border-top-color: #a9b9d3; }
  .alert-uiblue500 .alert-link {
    color: #020914; }

.alert-uiblue400 {
  color: #1a2d4e;
  background-color: #d6ddea;
  border-color: #c6d0e2; }
  .alert-uiblue400 hr {
    border-top-color: #b5c2d9; }
  .alert-uiblue400 .alert-link {
    color: #0d1728; }

.alert-uiblue300 {
  color: #33425b;
  background-color: #e0e5ef;
  border-color: #d3dbe9; }
  .alert-uiblue300 hr {
    border-top-color: #c2cde1; }
  .alert-uiblue300 .alert-link {
    color: #212a3a; }

.alert-uiblue200 {
  color: #4f5869;
  background-color: #eaeef4;
  border-color: #e2e7f0; }
  .alert-uiblue200 hr {
    border-top-color: #d1d9e7; }
  .alert-uiblue200 .alert-link {
    color: #39404c; }

.alert-uiblue100 {
  color: #666c75;
  background-color: #f3f6f9;
  border-color: #eff2f7; }
  .alert-uiblue100 hr {
    border-top-color: #dee4ef; }
  .alert-uiblue100 .alert-link {
    color: #4e535a; }

.alert-uiblue20 {
  color: #7c7d80;
  background-color: #fcfcfd;
  border-color: #fafbfc; }
  .alert-uiblue20 hr {
    border-top-color: #eaeef2; }
  .alert-uiblue20 .alert-link {
    color: #636466; }

.alert-accentyellow {
  color: #856300;
  background-color: #fff2cc;
  border-color: #ffedb8; }
  .alert-accentyellow hr {
    border-top-color: #ffe79f; }
  .alert-accentyellow .alert-link {
    color: #523d00; }

.alert-accentpink {
  color: #79213a;
  background-color: #fad9e2;
  border-color: #f9c9d7; }
  .alert-accentpink hr {
    border-top-color: #f6b2c6; }
  .alert-accentpink .alert-link {
    color: #511627; }

.alert-accentblue {
  color: #113c56;
  background-color: #d3e3ed;
  border-color: #c1d8e6; }
  .alert-accentblue hr {
    border-top-color: #afcddf; }
  .alert-accentblue .alert-link {
    color: #091e2b; }

.alert-accentlightblue {
  color: #003e76;
  background-color: #cce4f9;
  border-color: #b8d9f7; }
  .alert-accentlightblue hr {
    border-top-color: #a1cdf4; }
  .alert-accentlightblue .alert-link {
    color: #002343; }

.alert-accentgreen {
  color: #094b24;
  background-color: #d0e9da;
  border-color: #bde0cb; }
  .alert-accentgreen hr {
    border-top-color: #acd8bd; }
  .alert-accentgreen .alert-link {
    color: #041d0e; }

.alert-accentgrey {
  color: #7f8181;
  background-color: #fdfefe;
  border-color: #fcfdfd; }
  .alert-accentgrey hr {
    border-top-color: #edf3f3; }
  .alert-accentgrey .alert-link {
    color: #666767; }

.alert-urgent {
  color: #5d0a05;
  background-color: #f0d0ce;
  border-color: #e9bdba; }
  .alert-urgent hr {
    border-top-color: #e3aba7; }
  .alert-urgent .alert-link {
    color: #2d0502; }

.alert-pressing {
  color: #845600;
  background-color: #ffedcc;
  border-color: #ffe6b8; }
  .alert-pressing hr {
    border-top-color: #ffdd9f; }
  .alert-pressing .alert-link {
    color: #513500; }

.alert-cosmetic {
  color: #001d44;
  background-color: #ccd7e6;
  border-color: #b8c7dc; }
  .alert-cosmetic hr {
    border-top-color: #a7bad4; }
  .alert-cosmetic .alert-link {
    color: #000711; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #8560a7;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #8560a7;
    border-color: #8560a7; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #453257;
  background-color: #ddd2e6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #453257;
    background-color: #d1c2dd; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #453257;
    border-color: #453257; }

.list-group-item-secondary {
  color: #7c7d80;
  background-color: #fafbfc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7c7d80;
    background-color: #eaeef2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d80;
    border-color: #7c7d80; }

.list-group-item-success {
  color: #094b24;
  background-color: #bde0cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #094b24;
    background-color: #acd8bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #094b24;
    border-color: #094b24; }

.list-group-item-info {
  color: #113c56;
  background-color: #c1d8e6; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #113c56;
    background-color: #afcddf; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #113c56;
    border-color: #113c56; }

.list-group-item-warning {
  color: #845600;
  background-color: #ffe6b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #845600;
    background-color: #ffdd9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #845600;
    border-color: #845600; }

.list-group-item-danger {
  color: #661314;
  background-color: #eec2c3; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #661314;
    background-color: #e8aeaf; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #661314;
    border-color: #661314; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #797481;
  background-color: #f9f6fd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #797481;
    background-color: #ebe1f8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #797481;
    border-color: #797481; }

.list-group-item-theme {
  color: #858585;
  background-color: white; }
  .list-group-item-theme.list-group-item-action:hover, .list-group-item-theme.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-theme.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-black {
  color: black;
  background-color: #b8b8b8; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  .list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-mdblue {
  color: #051d44;
  background-color: #bac7dc; }
  .list-group-item-mdblue.list-group-item-action:hover, .list-group-item-mdblue.list-group-item-action:focus {
    color: #051d44;
    background-color: #a9b9d3; }
  .list-group-item-mdblue.list-group-item-action.active {
    color: #fff;
    background-color: #051d44;
    border-color: #051d44; }

.list-group-item-mdorange {
  color: #853e00;
  background-color: #ffd9b8; }
  .list-group-item-mdorange.list-group-item-action:hover, .list-group-item-mdorange.list-group-item-action:focus {
    color: #853e00;
    background-color: #ffcb9f; }
  .list-group-item-mdorange.list-group-item-action.active {
    color: #fff;
    background-color: #853e00;
    border-color: #853e00; }

.list-group-item-mdgreen {
  color: #094b24;
  background-color: #bde0cb; }
  .list-group-item-mdgreen.list-group-item-action:hover, .list-group-item-mdgreen.list-group-item-action:focus {
    color: #094b24;
    background-color: #acd8bd; }
  .list-group-item-mdgreen.list-group-item-action.active {
    color: #fff;
    background-color: #094b24;
    border-color: #094b24; }

.list-group-item-uigray500 {
  color: #333537;
  background-color: #d3d4d5; }
  .list-group-item-uigray500.list-group-item-action:hover, .list-group-item-uigray500.list-group-item-action:focus {
    color: #333537;
    background-color: #c6c7c9; }
  .list-group-item-uigray500.list-group-item-action.active {
    color: #fff;
    background-color: #333537;
    border-color: #333537; }

.list-group-item-uiblue700 {
  color: #010712;
  background-color: #b8bcc1; }
  .list-group-item-uiblue700.list-group-item-action:hover, .list-group-item-uiblue700.list-group-item-action:focus {
    color: #010712;
    background-color: #aaafb5; }
  .list-group-item-uiblue700.list-group-item-action.active {
    color: #fff;
    background-color: #010712;
    border-color: #010712; }

.list-group-item-uiblue600 {
  color: #020e23;
  background-color: #b9bfca; }
  .list-group-item-uiblue600.list-group-item-action:hover, .list-group-item-uiblue600.list-group-item-action:focus {
    color: #020e23;
    background-color: #aab2bf; }
  .list-group-item-uiblue600.list-group-item-action.active {
    color: #fff;
    background-color: #020e23;
    border-color: #020e23; }

.list-group-item-uiblue500 {
  color: #051d44;
  background-color: #bac7dc; }
  .list-group-item-uiblue500.list-group-item-action:hover, .list-group-item-uiblue500.list-group-item-action:focus {
    color: #051d44;
    background-color: #a9b9d3; }
  .list-group-item-uiblue500.list-group-item-action.active {
    color: #fff;
    background-color: #051d44;
    border-color: #051d44; }

.list-group-item-uiblue400 {
  color: #1a2d4e;
  background-color: #c6d0e2; }
  .list-group-item-uiblue400.list-group-item-action:hover, .list-group-item-uiblue400.list-group-item-action:focus {
    color: #1a2d4e;
    background-color: #b5c2d9; }
  .list-group-item-uiblue400.list-group-item-action.active {
    color: #fff;
    background-color: #1a2d4e;
    border-color: #1a2d4e; }

.list-group-item-uiblue300 {
  color: #33425b;
  background-color: #d3dbe9; }
  .list-group-item-uiblue300.list-group-item-action:hover, .list-group-item-uiblue300.list-group-item-action:focus {
    color: #33425b;
    background-color: #c2cde1; }
  .list-group-item-uiblue300.list-group-item-action.active {
    color: #fff;
    background-color: #33425b;
    border-color: #33425b; }

.list-group-item-uiblue200 {
  color: #4f5869;
  background-color: #e2e7f0; }
  .list-group-item-uiblue200.list-group-item-action:hover, .list-group-item-uiblue200.list-group-item-action:focus {
    color: #4f5869;
    background-color: #d1d9e7; }
  .list-group-item-uiblue200.list-group-item-action.active {
    color: #fff;
    background-color: #4f5869;
    border-color: #4f5869; }

.list-group-item-uiblue100 {
  color: #666c75;
  background-color: #eff2f7; }
  .list-group-item-uiblue100.list-group-item-action:hover, .list-group-item-uiblue100.list-group-item-action:focus {
    color: #666c75;
    background-color: #dee4ef; }
  .list-group-item-uiblue100.list-group-item-action.active {
    color: #fff;
    background-color: #666c75;
    border-color: #666c75; }

.list-group-item-uiblue20 {
  color: #7c7d80;
  background-color: #fafbfc; }
  .list-group-item-uiblue20.list-group-item-action:hover, .list-group-item-uiblue20.list-group-item-action:focus {
    color: #7c7d80;
    background-color: #eaeef2; }
  .list-group-item-uiblue20.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d80;
    border-color: #7c7d80; }

.list-group-item-accentyellow {
  color: #856300;
  background-color: #ffedb8; }
  .list-group-item-accentyellow.list-group-item-action:hover, .list-group-item-accentyellow.list-group-item-action:focus {
    color: #856300;
    background-color: #ffe79f; }
  .list-group-item-accentyellow.list-group-item-action.active {
    color: #fff;
    background-color: #856300;
    border-color: #856300; }

.list-group-item-accentpink {
  color: #79213a;
  background-color: #f9c9d7; }
  .list-group-item-accentpink.list-group-item-action:hover, .list-group-item-accentpink.list-group-item-action:focus {
    color: #79213a;
    background-color: #f6b2c6; }
  .list-group-item-accentpink.list-group-item-action.active {
    color: #fff;
    background-color: #79213a;
    border-color: #79213a; }

.list-group-item-accentblue {
  color: #113c56;
  background-color: #c1d8e6; }
  .list-group-item-accentblue.list-group-item-action:hover, .list-group-item-accentblue.list-group-item-action:focus {
    color: #113c56;
    background-color: #afcddf; }
  .list-group-item-accentblue.list-group-item-action.active {
    color: #fff;
    background-color: #113c56;
    border-color: #113c56; }

.list-group-item-accentlightblue {
  color: #003e76;
  background-color: #b8d9f7; }
  .list-group-item-accentlightblue.list-group-item-action:hover, .list-group-item-accentlightblue.list-group-item-action:focus {
    color: #003e76;
    background-color: #a1cdf4; }
  .list-group-item-accentlightblue.list-group-item-action.active {
    color: #fff;
    background-color: #003e76;
    border-color: #003e76; }

.list-group-item-accentgreen {
  color: #094b24;
  background-color: #bde0cb; }
  .list-group-item-accentgreen.list-group-item-action:hover, .list-group-item-accentgreen.list-group-item-action:focus {
    color: #094b24;
    background-color: #acd8bd; }
  .list-group-item-accentgreen.list-group-item-action.active {
    color: #fff;
    background-color: #094b24;
    border-color: #094b24; }

.list-group-item-accentgrey {
  color: #7f8181;
  background-color: #fcfdfd; }
  .list-group-item-accentgrey.list-group-item-action:hover, .list-group-item-accentgrey.list-group-item-action:focus {
    color: #7f8181;
    background-color: #edf3f3; }
  .list-group-item-accentgrey.list-group-item-action.active {
    color: #fff;
    background-color: #7f8181;
    border-color: #7f8181; }

.list-group-item-urgent {
  color: #5d0a05;
  background-color: #e9bdba; }
  .list-group-item-urgent.list-group-item-action:hover, .list-group-item-urgent.list-group-item-action:focus {
    color: #5d0a05;
    background-color: #e3aba7; }
  .list-group-item-urgent.list-group-item-action.active {
    color: #fff;
    background-color: #5d0a05;
    border-color: #5d0a05; }

.list-group-item-pressing {
  color: #845600;
  background-color: #ffe6b8; }
  .list-group-item-pressing.list-group-item-action:hover, .list-group-item-pressing.list-group-item-action:focus {
    color: #845600;
    background-color: #ffdd9f; }
  .list-group-item-pressing.list-group-item-action.active {
    color: #fff;
    background-color: #845600;
    border-color: #845600; }

.list-group-item-cosmetic {
  color: #001d44;
  background-color: #b8c7dc; }
  .list-group-item-cosmetic.list-group-item-action:hover, .list-group-item-cosmetic.list-group-item-action:focus {
    color: #001d44;
    background-color: #a7bad4; }
  .list-group-item-cosmetic.list-group-item-action.active {
    color: #fff;
    background-color: #001d44;
    border-color: #001d44; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #8560a7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #6b4c88 !important; }

.bg-secondary {
  background-color: #eef1f6 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cdd6e4 !important; }

.bg-success {
  background-color: #129145 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0c642f !important; }

.bg-info {
  background-color: #2174a5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #19567b !important; }

.bg-warning {
  background-color: #fea500 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb8400 !important; }

.bg-danger {
  background-color: #c42527 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #991d1e !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #e9e0f9 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #cbb5f1 !important; }

.bg-theme {
  background-color: #ffffff !important; }

a.bg-theme:hover, a.bg-theme:focus,
button.bg-theme:hover,
button.bg-theme:focus {
  background-color: #e6e6e6 !important; }

.bg-black {
  background-color: #000000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-mdblue {
  background-color: #0a3782 !important; }

a.bg-mdblue:hover, a.bg-mdblue:focus,
button.bg-mdblue:hover,
button.bg-mdblue:focus {
  background-color: #062353 !important; }

.bg-mdorange {
  background-color: #ff7700 !important; }

a.bg-mdorange:hover, a.bg-mdorange:focus,
button.bg-mdorange:hover,
button.bg-mdorange:focus {
  background-color: #cc5f00 !important; }

.bg-mdgreen {
  background-color: #129145 !important; }

a.bg-mdgreen:hover, a.bg-mdgreen:focus,
button.bg-mdgreen:hover,
button.bg-mdgreen:focus {
  background-color: #0c642f !important; }

.bg-uigray500 {
  background-color: #63666a !important; }

a.bg-uigray500:hover, a.bg-uigray500:focus,
button.bg-uigray500:hover,
button.bg-uigray500:focus {
  background-color: #4a4d50 !important; }

.bg-uiblue700 {
  background-color: #010e23 !important; }

a.bg-uiblue700:hover, a.bg-uiblue700:focus,
button.bg-uiblue700:hover,
button.bg-uiblue700:focus {
  background-color: black !important; }

.bg-uiblue600 {
  background-color: #041b43 !important; }

a.bg-uiblue600:hover, a.bg-uiblue600:focus,
button.bg-uiblue600:hover,
button.bg-uiblue600:focus {
  background-color: #010813 !important; }

.bg-uiblue500 {
  background-color: #0a3782 !important; }

a.bg-uiblue500:hover, a.bg-uiblue500:focus,
button.bg-uiblue500:hover,
button.bg-uiblue500:focus {
  background-color: #062353 !important; }

.bg-uiblue400 {
  background-color: #325796 !important; }

a.bg-uiblue400:hover, a.bg-uiblue400:focus,
button.bg-uiblue400:hover,
button.bg-uiblue400:focus {
  background-color: #254170 !important; }

.bg-uiblue300 {
  background-color: #637faf !important; }

a.bg-uiblue300:hover, a.bg-uiblue300:focus,
button.bg-uiblue300:hover,
button.bg-uiblue300:focus {
  background-color: #4c6693 !important; }

.bg-uiblue200 {
  background-color: #97aaca !important; }

a.bg-uiblue200:hover, a.bg-uiblue200:focus,
button.bg-uiblue200:hover,
button.bg-uiblue200:focus {
  background-color: #758eb9 !important; }

.bg-uiblue100 {
  background-color: #c5d0e1 !important; }

a.bg-uiblue100:hover, a.bg-uiblue100:focus,
button.bg-uiblue100:hover,
button.bg-uiblue100:focus {
  background-color: #a3b5d0 !important; }

.bg-uiblue20 {
  background-color: #eef1f6 !important; }

a.bg-uiblue20:hover, a.bg-uiblue20:focus,
button.bg-uiblue20:hover,
button.bg-uiblue20:focus {
  background-color: #cdd6e4 !important; }

.bg-accentyellow {
  background-color: #ffbf00 !important; }

a.bg-accentyellow:hover, a.bg-accentyellow:focus,
button.bg-accentyellow:hover,
button.bg-accentyellow:focus {
  background-color: #cc9900 !important; }

.bg-accentpink {
  background-color: #e83f6f !important; }

a.bg-accentpink:hover, a.bg-accentpink:focus,
button.bg-accentpink:hover,
button.bg-accentpink:focus {
  background-color: #da1a51 !important; }

.bg-accentblue {
  background-color: #2174a5 !important; }

a.bg-accentblue:hover, a.bg-accentblue:focus,
button.bg-accentblue:hover,
button.bg-accentblue:focus {
  background-color: #19567b !important; }

.bg-accentlightblue {
  background-color: #0077e2 !important; }

a.bg-accentlightblue:hover, a.bg-accentlightblue:focus,
button.bg-accentlightblue:hover,
button.bg-accentlightblue:focus {
  background-color: #005caf !important; }

.bg-accentgreen {
  background-color: #129145 !important; }

a.bg-accentgreen:hover, a.bg-accentgreen:focus,
button.bg-accentgreen:hover,
button.bg-accentgreen:focus {
  background-color: #0c642f !important; }

.bg-accentgrey {
  background-color: #f4f8f9 !important; }

a.bg-accentgrey:hover, a.bg-accentgrey:focus,
button.bg-accentgrey:hover,
button.bg-accentgrey:focus {
  background-color: #d3e3e7 !important; }

.bg-urgent {
  background-color: #b2140a !important; }

a.bg-urgent:hover, a.bg-urgent:focus,
button.bg-urgent:hover,
button.bg-urgent:focus {
  background-color: #820f07 !important; }

.bg-pressing {
  background-color: #fea500 !important; }

a.bg-pressing:hover, a.bg-pressing:focus,
button.bg-pressing:hover,
button.bg-pressing:focus {
  background-color: #cb8400 !important; }

.bg-cosmetic {
  background-color: #003782 !important; }

a.bg-cosmetic:hover, a.bg-cosmetic:focus,
button.bg-cosmetic:hover,
button.bg-cosmetic:focus {
  background-color: #00214f !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #8560a7 !important; }

.border-secondary {
  border-color: #eef1f6 !important; }

.border-success {
  border-color: #129145 !important; }

.border-info {
  border-color: #2174a5 !important; }

.border-warning {
  border-color: #fea500 !important; }

.border-danger {
  border-color: #c42527 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #e9e0f9 !important; }

.border-theme {
  border-color: #ffffff !important; }

.border-black {
  border-color: #000000 !important; }

.border-mdblue {
  border-color: #0a3782 !important; }

.border-mdorange {
  border-color: #ff7700 !important; }

.border-mdgreen {
  border-color: #129145 !important; }

.border-uigray500 {
  border-color: #63666a !important; }

.border-uiblue700 {
  border-color: #010e23 !important; }

.border-uiblue600 {
  border-color: #041b43 !important; }

.border-uiblue500 {
  border-color: #0a3782 !important; }

.border-uiblue400 {
  border-color: #325796 !important; }

.border-uiblue300 {
  border-color: #637faf !important; }

.border-uiblue200 {
  border-color: #97aaca !important; }

.border-uiblue100 {
  border-color: #c5d0e1 !important; }

.border-uiblue20 {
  border-color: #eef1f6 !important; }

.border-accentyellow {
  border-color: #ffbf00 !important; }

.border-accentpink {
  border-color: #e83f6f !important; }

.border-accentblue {
  border-color: #2174a5 !important; }

.border-accentlightblue {
  border-color: #0077e2 !important; }

.border-accentgreen {
  border-color: #129145 !important; }

.border-accentgrey {
  border-color: #f4f8f9 !important; }

.border-urgent {
  border-color: #b2140a !important; }

.border-pressing {
  border-color: #fea500 !important; }

.border-cosmetic {
  border-color: #003782 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #8560a7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #5e4278 !important; }

.text-secondary {
  color: #eef1f6 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #bcc8dc !important; }

.text-success {
  color: #129145 !important; }

a.text-success:hover, a.text-success:focus {
  color: #0a4d25 !important; }

.text-info {
  color: #2174a5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #144765 !important; }

.text-warning {
  color: #fea500 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b27300 !important; }

.text-danger {
  color: #c42527 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #84191a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #e9e0f9 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #bca0ed !important; }

.text-theme {
  color: #ffffff !important; }

a.text-theme:hover, a.text-theme:focus {
  color: #d9d9d9 !important; }

.text-black {
  color: #000000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-mdblue {
  color: #0a3782 !important; }

a.text-mdblue:hover, a.text-mdblue:focus {
  color: #05193b !important; }

.text-mdorange {
  color: #ff7700 !important; }

a.text-mdorange:hover, a.text-mdorange:focus {
  color: #b35300 !important; }

.text-mdgreen {
  color: #129145 !important; }

a.text-mdgreen:hover, a.text-mdgreen:focus {
  color: #0a4d25 !important; }

.text-uigray500 {
  color: #63666a !important; }

a.text-uigray500:hover, a.text-uigray500:focus {
  color: #3e4042 !important; }

.text-uiblue700 {
  color: #010e23 !important; }

a.text-uiblue700:hover, a.text-uiblue700:focus {
  color: black !important; }

.text-uiblue600 {
  color: #041b43 !important; }

a.text-uiblue600:hover, a.text-uiblue600:focus {
  color: black !important; }

.text-uiblue500 {
  color: #0a3782 !important; }

a.text-uiblue500:hover, a.text-uiblue500:focus {
  color: #05193b !important; }

.text-uiblue400 {
  color: #325796 !important; }

a.text-uiblue400:hover, a.text-uiblue400:focus {
  color: #1f365d !important; }

.text-uiblue300 {
  color: #637faf !important; }

a.text-uiblue300:hover, a.text-uiblue300:focus {
  color: #435a83 !important; }

.text-uiblue200 {
  color: #97aaca !important; }

a.text-uiblue200:hover, a.text-uiblue200:focus {
  color: #6481b0 !important; }

.text-uiblue100 {
  color: #c5d0e1 !important; }

a.text-uiblue100:hover, a.text-uiblue100:focus {
  color: #93a7c7 !important; }

.text-uiblue20 {
  color: #eef1f6 !important; }

a.text-uiblue20:hover, a.text-uiblue20:focus {
  color: #bcc8dc !important; }

.text-accentyellow {
  color: #ffbf00 !important; }

a.text-accentyellow:hover, a.text-accentyellow:focus {
  color: #b38600 !important; }

.text-accentpink {
  color: #e83f6f !important; }

a.text-accentpink:hover, a.text-accentpink:focus {
  color: #c31748 !important; }

.text-accentblue {
  color: #2174a5 !important; }

a.text-accentblue:hover, a.text-accentblue:focus {
  color: #144765 !important; }

.text-accentlightblue {
  color: #0077e2 !important; }

a.text-accentlightblue:hover, a.text-accentlightblue:focus {
  color: #004f96 !important; }

.text-accentgreen {
  color: #129145 !important; }

a.text-accentgreen:hover, a.text-accentgreen:focus {
  color: #0a4d25 !important; }

.text-accentgrey {
  color: #f4f8f9 !important; }

a.text-accentgrey:hover, a.text-accentgrey:focus {
  color: #c3d9de !important; }

.text-urgent {
  color: #b2140a !important; }

a.text-urgent:hover, a.text-urgent:focus {
  color: #6a0c06 !important; }

.text-pressing {
  color: #fea500 !important; }

a.text-pressing:hover, a.text-pressing:focus {
  color: #b27300 !important; }

.text-cosmetic {
  color: #003782 !important; }

a.text-cosmetic:hover, a.text-cosmetic:focus {
  color: #001736 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.btn-primary:hover {
  background: #8560a7;
  border: #8560a7; }

.btn-secondary:hover {
  background: #eef1f6;
  border: #eef1f6; }

.btn-success:hover {
  background: #129145;
  border: #129145; }

.btn-info:hover {
  background: #2174a5;
  border: #2174a5; }

.btn-warning:hover {
  background: #fea500;
  border: #fea500; }

.btn-danger:hover {
  background: #c42527;
  border: #c42527; }

.btn-light:hover {
  background: #f8f9fa;
  border: #f8f9fa; }

.btn-dark:hover {
  background: #e9e0f9;
  border: #e9e0f9; }

.btn-theme:hover {
  background: #ffffff;
  border: #ffffff; }

.btn-black:hover {
  background: #000000;
  border: #000000; }

.btn-mdblue:hover {
  background: #0a3782;
  border: #0a3782; }

.btn-mdorange:hover {
  background: #ff7700;
  border: #ff7700; }

.btn-mdgreen:hover {
  background: #129145;
  border: #129145; }

.btn-uigray500:hover {
  background: #63666a;
  border: #63666a; }

.btn-uiblue700:hover {
  background: #010e23;
  border: #010e23; }

.btn-uiblue600:hover {
  background: #041b43;
  border: #041b43; }

.btn-uiblue500:hover {
  background: #0a3782;
  border: #0a3782; }

.btn-uiblue400:hover {
  background: #325796;
  border: #325796; }

.btn-uiblue300:hover {
  background: #637faf;
  border: #637faf; }

.btn-uiblue200:hover {
  background: #97aaca;
  border: #97aaca; }

.btn-uiblue100:hover {
  background: #c5d0e1;
  border: #c5d0e1; }

.btn-uiblue20:hover {
  background: #eef1f6;
  border: #eef1f6; }

.btn-accentyellow:hover {
  background: #ffbf00;
  border: #ffbf00; }

.btn-accentpink:hover {
  background: #e83f6f;
  border: #e83f6f; }

.btn-accentblue:hover {
  background: #2174a5;
  border: #2174a5; }

.btn-accentlightblue:hover {
  background: #0077e2;
  border: #0077e2; }

.btn-accentgreen:hover {
  background: #129145;
  border: #129145; }

.btn-accentgrey:hover {
  background: #f4f8f9;
  border: #f4f8f9; }

.btn-urgent:hover {
  background: #b2140a;
  border: #b2140a; }

.btn-pressing:hover {
  background: #fea500;
  border: #fea500; }

.btn-cosmetic:hover {
  background: #003782;
  border: #003782; }

.mad-primary {
  background: #8560a7; }

.mad-secondary {
  background: #eef1f6; }

.mad-success {
  background: #129145; }

.mad-info {
  background: #2174a5; }

.mad-warning {
  background: #fea500; }

.mad-danger {
  background: #c42527; }

.mad-light {
  background: #f8f9fa; }

.mad-dark {
  background: #e9e0f9; }

.mad-theme {
  background: #ffffff; }

.mad-black {
  background: #000000; }

.mad-mdblue {
  background: #0a3782; }

.mad-mdorange {
  background: #ff7700; }

.mad-mdgreen {
  background: #129145; }

.mad-uigray500 {
  background: #63666a; }

.mad-uiblue700 {
  background: #010e23; }

.mad-uiblue600 {
  background: #041b43; }

.mad-uiblue500 {
  background: #0a3782; }

.mad-uiblue400 {
  background: #325796; }

.mad-uiblue300 {
  background: #637faf; }

.mad-uiblue200 {
  background: #97aaca; }

.mad-uiblue100 {
  background: #c5d0e1; }

.mad-uiblue20 {
  background: #eef1f6; }

.mad-accentyellow {
  background: #ffbf00; }

.mad-accentpink {
  background: #e83f6f; }

.mad-accentblue {
  background: #2174a5; }

.mad-accentlightblue {
  background: #0077e2; }

.mad-accentgreen {
  background: #129145; }

.mad-accentgrey {
  background: #f4f8f9; }

.mad-urgent {
  background: #b2140a; }

.mad-pressing {
  background: #fea500; }

.mad-cosmetic {
  background: #003782; }

.bg-black {
  color: #000000; }

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.btn-large {
  border-radius: 0;
  min-height: 225px;
  min-width: 250px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.6666667em;
  text-align: left;
  font-family: "Lato", sans-serif;
  padding: 1.6666666667em;
  transform-origin: 50% 50% 50%;
  backface-visibility: hidden; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .btn-large {
      min-height: 90%;
      min-width: 90%;
      padding: 1.5em;
      font-size: 1.5em; } }
  @media screen and (max-width: 768px) {
    .btn-large {
      min-height: 100%;
      min-width: 100%;
      padding: 1.5em;
      font-size: 1.5em; } }
  .btn-large div {
    margin-top: auto; }
  .btn-large:hover {
    transform: perspective(1px) scale(1.03) translate3d(0, 0, 0);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25); }

.mad-spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mad-spinner-container .mad-loading-spinner {
    border: 3px solid #0077e2;
    border-top-color: #0a3782;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #fff !important; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  font-weight: 900; }

html {
  height: 100%;
  scroll-behavior: smooth; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
